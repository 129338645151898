import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { titleLabels } from "../../constants/TitleLabels";
import Controls from "../../components/controls/Controls";
import Loader from "../../components/Loader";
import ToastMessage from "../../components/ToastMessage";
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Chip,
  Input,
} from "@mui/material";
import environment from "../../environment";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
import { messages } from "../../constants/Messages";
import "./configurations.scss";
import { ALL_MODULES } from "../../constants/Modules";
import { DATASYNC_EVENT_PRIVILEGES } from "./constants";

const useStyles = makeStyles((theme) => ({}));

const Configurations = ({
  type,
  rolesRawData,
  setOpenPopup,
  setRefreshRoles,
  setToast,
}) => {
  const classes = useStyles();
  const [roleName, setRoleName] = React.useState("");
  const [roleNameError, setRoleNameError] = React.useState("");
  const [moduleRP, setModuleRP] = React.useState(["Home"]);
  const [homePrivilege, setHomePrivilege] = React.useState([]);
  const [reportsPrivilege, setReportsPrivilege] = React.useState([]);
  const [cllmPrivilege, setCllmPrivilege] = React.useState([]);

  const [preProvisioningPrivilege, setPreProvisioningPrivilege] =
    React.useState([]);
  const [requestsPrivilege, setRequestsPrivilege] = React.useState([]);
  const [companiesPrivilege, setCompaniesPrivilege] = React.useState([]);
  const [billingPrivilege, setBillingPrivilege] = React.useState([]);
  const [servicesPrivilege, setServicesPrivilege] = React.useState([]);
  const [chargerHealthPrivilege, setChargerHealthPrivilege] = React.useState(
    []
  );
  const [chargerDetailsModulePrivilege, setChargerDetailsModulePrivilege] =
    React.useState([]);

  const [usageAnalyticsPrivilege, setUsageAnalyticsPrivilege] = React.useState(
    []
  );
  const [dataCatECPrivilege, setDataCatECPrivilege] = React.useState([]);
  const [dataCatVVMPrivilege, setDataCatVVMPrivilege] = React.useState([]);
  const [dataCatCGPrivilege, setDataCatCGPrivilege] = React.useState([]);
  const [dataCatVCPrivilege, setDataCatVCPrivilege] = React.useState([]);
  const [dataCatURPrivilege, setDataCatURPrivilege] = React.useState([]);
  const [datasyncEventPrivilege, setDatasyncEventPrivilege] = React.useState(
    []
  );
  const [loading, setLoading] = React.useState(false);

  const { ROLES } = indexedEndPoints;

  useEffect(() => {
    if (type === "addRole") return;
    setRoleName(rolesRawData?.name);
    setModuleRP(rolesRawData?.rolePrivilege);

    if ("home" in rolesRawData?.componentPrivilege) {
      setHomePrivilege(rolesRawData?.componentPrivilege?.home);
    } else {
      setHomePrivilege([]);
    }
    if ("reports" in rolesRawData?.componentPrivilege) {
      setReportsPrivilege(rolesRawData?.componentPrivilege?.reports);
    } else {
      setReportsPrivilege([]);
    }
    if ("cllm" in rolesRawData?.componentPrivilege) {
      setCllmPrivilege(rolesRawData?.componentPrivilege?.cllm);
    } else {
      setCllmPrivilege([]);
    }

    if ("preProvisioning" in rolesRawData?.componentPrivilege) {
      setPreProvisioningPrivilege(
        rolesRawData?.componentPrivilege?.preProvisioning
      );
    } else {
      setPreProvisioningPrivilege([]);
    }
    if ("companies" in rolesRawData?.componentPrivilege) {
      setCompaniesPrivilege(rolesRawData?.componentPrivilege?.companies);
    } else {
      setCompaniesPrivilege([]);
    }
    if ("requests" in rolesRawData?.componentPrivilege) {
      setRequestsPrivilege(rolesRawData?.componentPrivilege?.requests);
    } else {
      setRequestsPrivilege([]);
    }
    if ("billing" in rolesRawData?.componentPrivilege) {
      setBillingPrivilege(rolesRawData?.componentPrivilege?.billing);
    } else {
      setBillingPrivilege([]);
    }
    if ("services" in rolesRawData?.componentPrivilege) {
      setServicesPrivilege(rolesRawData?.componentPrivilege?.services);
    } else {
      setServicesPrivilege([]);
    }
    if ("dataCatalogErrorCode" in rolesRawData?.componentPrivilege) {
      setDataCatECPrivilege(
        rolesRawData?.componentPrivilege?.dataCatalogErrorCode
      );
    } else {
      setDataCatECPrivilege([]);
    }
    if ("dataCatalogCharger" in rolesRawData?.componentPrivilege) {
      setDataCatCGPrivilege(
        rolesRawData?.componentPrivilege?.dataCatalogCharger
      );
    } else {
      setDataCatCGPrivilege([]);
    }
    if ("dataCatalogVehicle" in rolesRawData?.componentPrivilege) {
      setDataCatVCPrivilege(
        rolesRawData?.componentPrivilege?.dataCatalogVehicle
      );
    } else {
      setDataCatVCPrivilege([]);
    }
    if ("dataCatalogVinVidMapping" in rolesRawData?.componentPrivilege) {
      setDataCatVVMPrivilege(
        rolesRawData?.componentPrivilege?.dataCatalogVinVidMapping
      );
    } else {
      setDataCatVVMPrivilege([]);
    }
    if ("dataCatalogUtilityRate" in rolesRawData?.componentPrivilege) {
      setDataCatURPrivilege(
        rolesRawData?.componentPrivilege?.dataCatalogUtilityRate
      );
    } else {
      setDataCatURPrivilege([]);
    }
    if ("chargerHealth" in rolesRawData?.componentPrivilege) {
      setChargerHealthPrivilege(
        rolesRawData?.componentPrivilege?.chargerHealth
      );
    } else {
      setChargerHealthPrivilege([]);
    }
    if ("chargerDetails" in rolesRawData?.componentPrivilege) {
      setChargerDetailsModulePrivilege(
        rolesRawData?.componentPrivilege?.chargerDetails
      );
    } else {
      setChargerDetailsModulePrivilege([]);
    }
    if ("datasyncEvents" in rolesRawData?.componentPrivilege) {
      setDatasyncEventPrivilege(
        rolesRawData?.componentPrivilege?.datasyncEvents
      );
    } else {
      setDatasyncEventPrivilege([]);
    }
  }, [type, rolesRawData]);

  const handleModuleRP = (event) => {
    setModuleRP(event.target.value);
  };

  const handleHomePrivilege = (event) => {
    setHomePrivilege(event.target.value);
  };

  const handleReportsPrivilege = (event) => {
    setReportsPrivilege(event.target.value);
  };

  const handleCllmPrivilege = (event) => {
    setCllmPrivilege(event.target.value);
  };

  const handlePreProvisioningPrivilege = (event) => {
    setPreProvisioningPrivilege(event.target.value);
  };
  const handleRequestsPrivilege = (event) => {
    setRequestsPrivilege(event.target.value);
  };
  const handleCompaniesPrivilege = (event) => {
    setCompaniesPrivilege(event.target.value);
  };
  const handleBillingPrivilege = (event) => {
    setBillingPrivilege(event.target.value);
  };
  const handleServicesPrivilege = (event) => {
    setServicesPrivilege(event.target.value);
  };
  const HandleChargerHealthPrivilege = (event) => {
    setChargerHealthPrivilege(event.target.value);
  };

  const HandleChargerDetailsModulePrivilege = (event) => {
    setChargerDetailsModulePrivilege(event.target.value);
  };

  const HandleUsageAnalyticsPrivilege = (event) => {
    setUsageAnalyticsPrivilege(event.target.value);
  };
  const handleDataCatECPrivilege = (event) => {
    setDataCatECPrivilege(event.target.value);
  };
  const handleDataCatVVMPrivilege = (event) => {
    setDataCatVVMPrivilege(event.target.value);
  };
  const handleDataCatCGPrivilege = (event) => {
    setDataCatCGPrivilege(event.target.value);
  };
  const handleDataCatVCPrivilege = (event) => {
    setDataCatVCPrivilege(event.target.value);
  };
  const handleDataCatURPrivilege = (event) => {
    setDataCatURPrivilege(event.target.value);
  };
  const handleDatasyncEventPrivilege = (event) => {
    setDatasyncEventPrivilege(event.target.value);
  };

  const onSaveConfigurations = async () => {
    setRoleNameError(roleName?.trim()?.length ? "" : messages.FIELD_REQUIRED);
    if (!roleName?.trim()?.length) return;
    const bodyRequest = {
      name: roleName?.trim(),
      rolePrivilege: moduleRP,
      componentPrivilege: {
        home: homePrivilege,
        chargerHealth: chargerHealthPrivilege,
        chargerDetails: chargerDetailsModulePrivilege,
        cllm: cllmPrivilege,

        requests: requestsPrivilege,
        companies: companiesPrivilege,
        billing: billingPrivilege,
        services: servicesPrivilege,
        preProvisioning: preProvisioningPrivilege,
        reports: reportsPrivilege,
        dataCatalogErrorCode: dataCatECPrivilege,
        dataCatalogCharger: dataCatCGPrivilege,
        dataCatalogVinVidMapping: dataCatVVMPrivilege,
        dataCatalogUtilityRate: dataCatURPrivilege,
        dataCatalogVehicle: dataCatVCPrivilege,
        datasyncEvents: datasyncEventPrivilege,
      },
    };
    setLoading(true);
    if (type === "addRole") {
      const response = await apiProvider.post(ROLES, bodyRequest);
      if (response.statusCode >= 200 && response.statusCode <= 299) {
        setToast({
          isOpen: true,
          message:
            "Role added successfully. Please ensure to create a new role with the same name in Azure Portal in the Operations Console APP",
          type: "success",
        });
        setOpenPopup((op) => ({ ...op, isOpen: false }));
        setRefreshRoles(true);
      } else {
        setToast({
          isOpen: true,
          message: response?.data?.data,
          type: "error",
        });
      }
    } else {
      const response = await apiProvider.put(
        `${ROLES}/${rolesRawData?.roleId}`,
        bodyRequest
      );
      if (response.statusCode >= 200 && response.statusCode <= 299) {
        setToast({
          isOpen: true,
          message:
            "Role updated successfully. Please remember to update the Rolename in Azure Portal if it has been changed.",
          type: "success",
        });
        setOpenPopup((op) => ({ ...op, isOpen: false }));
        setRefreshRoles(true);
      } else {
        setToast({
          isOpen: true,
          message: response?.data?.data,
          type: "error",
        });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Loader isLoading={loading} />

      <div className="roleModal">
        <Typography variant="h5">{titleLabels.ROLE_PRIVILEGE_TITLE}</Typography>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Controls.Input
              name="role"
              label="Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              error={roleNameError}
            />
          </Grid>
          <Grid item xs={9}>
            <FormControl fullWidth>
              <InputLabel id="demo-mutiple-chip-label">Modules</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={moduleRP}
                onChange={handleModuleRP}
                data-testid={"moduleMultiselect"}
                input={<Input id="select-multiple-chip" />}
                MenuProps={{ "data-testid": "ModuleOptions" }}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              >
                {ALL_MODULES.map((name) => (
                  <MenuItem
                    data-testid={name}
                    disabled={name === "Home" ? true : false}
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Modules</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <div>
          <Typography variant="h5">
            {titleLabels.MODULE_PRIVILEGE_TITLE}
          </Typography>
          <Controls.MultiSelect
            name="Home"
            testid="homeModule"
            options={[]}
            value={homePrivilege}
            caption="Select Internal Components of Home"
            onChange={handleHomePrivilege}
          />
          <Controls.MultiSelect
            name="Charger Health"
            testid="chargerHealthModule"
            options={[
              "Update Fault Status",
              "Faults - Edit Notes Details",
              "Connectivity - Edit Notes Details",
            ]}
            value={chargerHealthPrivilege}
            caption="Select Internal Components of Charger Health"
            onChange={HandleChargerHealthPrivilege}
          />

          <Controls.MultiSelect
            name="Chargers"
            testid="chargerDetailsModule"
            options={[
              "Replace Charger",
              "Disconnect Charger",
              "Temperature Alert Settings",
              "Manage Charger",
              "Manage Port",
              "Charger Credential",
              "Request Diagnostics",
              "Disconnect Charger Websocket",
            ]}
            value={chargerDetailsModulePrivilege}
            caption="Select Internal Components of Chargers"
            onChange={HandleChargerDetailsModulePrivilege}
          />

          <Controls.MultiSelect
            name="Usage Analytics"
            testid="usageAnalyticsModule"
            value={usageAnalyticsPrivilege}
            options={[]}
            caption="Select Internal Components of Usage Analytics"
            onChange={HandleUsageAnalyticsPrivilege}
          />
          <Controls.MultiSelect
            name="Circuit Monitor"
            testid="cllmModule"
            options={["CLLM Alert Settings", "Alert Log Status"]}
            value={cllmPrivilege}
            caption="Select Internal Components of Circuit Monitor"
            onChange={handleCllmPrivilege}
          />

          <Controls.MultiSelect
            name="Requests"
            testid="requestsModule"
            options={[
              "Requests Action Column",
              "On Hold Action Column",
              "Shared Charging Toggle",
            ]}
            value={requestsPrivilege}
            caption="Select Internal Components of Requests"
            onChange={handleRequestsPrivilege}
          />
          <Controls.MultiSelect
            name="Companies"
            testid="companiesModule"
            options={[
              "Update Data Sync",
              "Edit Depot Preferences",
              "Status History",
              "Depot Infrastructure",
              "Toggle Depot Infrastructure",
            ]}
            value={companiesPrivilege}
            caption="Select Internal Components of Companies"
            onChange={handleCompaniesPrivilege}
          />
          <Controls.MultiSelect
            name="Billing"
            testid="billingModule"
            options={[
              "Raise Invoice",
              "Billing History",
              "Update Billing Package",
              "Change Rate Plan",
            ]}
            value={billingPrivilege}
            caption="Select Internal Components of Billing"
            onChange={handleBillingPrivilege}
          />
          <Controls.MultiSelect
            name="Services"
            testid="servicesModule"
            options={["Test Firmware Updates"]}
            value={servicesPrivilege}
            caption="Select Internal Components of Services"
            onChange={handleServicesPrivilege}
          />
          <Controls.MultiSelect
            name="Pre Provisioning"
            testid="preProvisioningModule"
            options={environment?.[
              process.env.REACT_APP_NODE_ENV
            ]?.PRE_PROVISIONING_DEPOTS?.map((value) => value?.depotName)}
            value={preProvisioningPrivilege}
            caption="Select Internal Components of Pre Provisioning"
            onChange={handlePreProvisioningPrivilege}
          />
          <Controls.MultiSelect
            name="Reports"
            testid="reportsModule"
            options={[]}
            value={reportsPrivilege}
            caption="Select Internal Components of Reports"
            onChange={handleReportsPrivilege}
          />
          <Controls.MultiSelect
            name="Data Catalog Error Codes"
            testid="errorCodeCatalogModule"
            options={[
              "Manage groups",
              "Add Error Code",
              "Delete Error Code",
              "Update Error Code",
              "Bulk Upload",
            ]}
            value={dataCatECPrivilege}
            caption="Select Internal Components of Data Catalog Error Codes"
            onChange={handleDataCatECPrivilege}
          />
          <Controls.MultiSelect
            name="Data Catalog Chargers"
            testid="chargerCatalogModule"
            options={[
              "Add Manufacturer",
              "Add Charger",
              // "Update Charger",
              // "Bulk Upload",
              "Manage Firmware",
            ]}
            value={dataCatCGPrivilege}
            caption="Select Internal Components of Data Catalog Chargers"
            onChange={handleDataCatCGPrivilege}
          />
          <Controls.MultiSelect
            name="Data Catalog VIN-VID Mapping"
            testid="vinVidCatalogModule"
            options={[
              "Add VIN-VID Mapping",
              // "Update VIN-VID Mapping",
              // "Delete VIN-VID Mapping",
              "Bulk Upload",
            ]}
            value={dataCatVVMPrivilege}
            caption="Select Internal Components of Data Catalog VIN-VID Mapping"
            onChange={handleDataCatVVMPrivilege}
          />
          <Controls.MultiSelect
            name="Data Catalog Utility Rates"
            testid="utilityRateCatalogModule"
            options={[
              "Add Provider",
              "Update Provider",
              "Delete Provider",
              "Add Plan",
              "Update Plan",
              "Delete Plan",
              "View Plan Rates",
              "Upload Plan Rates",
            ]}
            value={dataCatURPrivilege}
            caption="Select Internal Components of Data Catalog Utility Rates"
            onChange={handleDataCatURPrivilege}
          />
          <Controls.MultiSelect
            name="Data Catalog Vehicles"
            testid="vehicleCatalogModule"
            options={[
              "Add Vehicle",
              "Delete Vehicle",
              "Update Vehicle",
              "Bulk Upload",
            ]}
            value={dataCatVCPrivilege}
            caption="Select Internal Components of Data Catalog Vehicles"
            onChange={handleDataCatVCPrivilege}
          />
          <Controls.MultiSelect
            name="Datasync Events"
            testid="datasyncEventsModule"
            options={DATASYNC_EVENT_PRIVILEGES}
            value={datasyncEventPrivilege}
            caption="Select Internal Components of Datasync Events"
            onChange={handleDatasyncEventPrivilege}
          />
        </div>
      </div>
      <div className="saveRoleConfigs">
        <Controls.Button
          text="Save"
          data-testid="saveRolesBtn"
          onClick={onSaveConfigurations}
        />
      </div>
    </>
  );
};

export default Configurations;
