import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const {
  VEHICLES_DISCOVERY_METRICS,
  VEHICLES_DISCOVERY_IMPROVEMENT_METRICS,
  VEHICLES_DISCOVERY_CHECK_VIN_VID_MAPPING,
} = indexedEndPoints;

export const getVehiclesDiscovery = async (payload) =>
  await apiProvider.post(VEHICLES_DISCOVERY_METRICS, payload);

export const getVehiclesDiscoveryImprovementMetrics = async (payload) =>
  await apiProvider.post(VEHICLES_DISCOVERY_IMPROVEMENT_METRICS, payload);

export const checkVINVIDMappingExists = async (payload) =>
  await apiProvider.post(VEHICLES_DISCOVERY_CHECK_VIN_VID_MAPPING, payload);

export default {
  getVehiclesDiscovery,
  getVehiclesDiscoveryImprovementMetrics,
  checkVINVIDMappingExists,
};
