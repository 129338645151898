import React, { useState } from "react";
import { Grid } from "@mui/material";
import { getCredentialByChargerOrFesn } from "../chargerFirmwareService";
import Controls from "../../../../components/controls/Controls";

const FindChargerCredential = (props) => {
  const [data, setData] = useState({});
  const [chargerSerialNumber, setChargerSerialNumber] = useState("");

  const handleChargerCredential = async () => {
    props.setLoading(true);
    const response = await getCredentialByChargerOrFesn(chargerSerialNumber);
    const data = { ...response.data };
    props.setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setData(data);
    } else {
      props.setToast({
        isOpen: true,
        message: "Failed to retrieve charger credentials",
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <div className="edit_charger_height">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                name="chargerSerialNumber"
                label="Enter Charger Serial Number"
                value={chargerSerialNumber}
                onChange={(e) => setChargerSerialNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Button
                text="Find Credentials"
                color="primary"
                onClick={handleChargerCredential}
                className="btn_font_size"
                disabled={!chargerSerialNumber}
              />
            </Grid>
            {Object.keys(data).length > 0 && (
              <Grid item xs={12}>
                <Controls.CopyToClipboard
                  name={data}
                  isJson={true}
                  max_Height={"600px"}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default FindChargerCredential;
