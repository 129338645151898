export const mapRouteToTabName = {
  "/": "Home",
  "/login": "",
  "/userAlertSettings": "Home",
  "/create-account": "",
  "/customers": "",
  "/instances": "Instances",
  "/service-status": "Service Status",
  "/reports": "Reports",
  "/charger-health": "Charger Health",
  "/charger-health/open-Faults": "Charger Health",
  "/charger-health/offline-Ports": "Charger Health",
  "/charger-details": "Chargers",
  "/charger-details/charger-history": "Chargers",
  "/charger-details/history": "Chargers",
  "/charger-details/replacement-history": "Chargers",
  "/users": "Users",
  "/usage-analytics": "Usage Analytics",
  "/requests": "Requests",
  "/companies": "Companies",
  "/billing": "Billing",
  "/billing/depots": "Company Depot Details",
  "/configurations": "Configurations",
  "/requests/reviewconfirm": "Review and confirm",
  "/companies/depots": "Company Depot Details",
  "/services": "Services",
  "/services/scheduled": "Services",
  "/services/history": "Services",
  "/pre-provisioning": "Pre Provisioning",
  "/history": "Billing history",
  "/dataCatalog": "Data Catalog",
  "/dataCatalog/errorCodesDashboard": "Data Catalog",
  "/dataCatalog/errorCodesDashboard/errorCodes": "Data Catalog",
  "/dataCatalog/errorCodesDashboard/errorCodes/bulkUpload": "Data Catalog",
  "/dataCatalog/chargersDashboard": "Data Catalog",
  "/dataCatalog/chargersDashboard/chargers": "Data Catalog",
  "/dataCatalog/chargersDashboard/chargers/bulkUpload": "Data Catalog",
  "/dataCatalog/vinVidMapping": "Data Catalog",
  "/dataCatalog/vinVidMapping/bulkUpload": "Data Catalog",
  "/dataCatalog/utilityRates": "Data Catalog",
  "/dataCatalog/utilityRates/provider": "Data Catalog",
  "/dataCatalog/utilityRates/searchPlans": "Data Catalog",
  "/vehicles": "Vehicle Discovery",
  "/vehicles/depot-details": "Vehicle Discovery",
  "/datasync/events": "Datasync Events",
};
