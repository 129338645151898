import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, InputAdornment, Toolbar, Switch } from "@mui/material";
import Controls from "../../../components/controls/Controls";
import { Form, UseForm } from "../../../components/UseForm";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Loader from "../../../components/Loader";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";
import { messages } from "../../../constants/Messages";
import environment from "../../../environment";
import useTable from "../../../components/UseTable";
import { chargerStatusIndicatorList } from "./errorCodeConstants";

const AddEditErrorCode = ({
  groupId,
  manufacturer,
  type,
  openPopup,
  setOpenPopup,
  setRefreshErrorCodes,
  setToast,
  languages,
}) => {
  const { ADD_ERROR_CODE, ERROR_CODE } = indexedEndPoints;
  const [loading, setLoading] = useState(false);

  const addEditInitialValues = useMemo(
    () => ({
      errorCode: type === "updateErrorCode" ? openPopup?.item?.errorCode : "",
      faultSource:
        type === "updateErrorCode" ? openPopup?.item?.faultSource : "",
      faultTitle: type === "updateErrorCode" ? openPopup?.item?.faultTitle : "",
      faultDefinition:
        type === "updateErrorCode" ? openPopup?.item?.faultDefinition : "",
      description:
        type === "updateErrorCode" ? openPopup?.item?.description : "",
      manufacturer: manufacturer,
      severity: type === "updateErrorCode" ? openPopup?.item?.severity : "",
      language: type === "updateErrorCode" ? openPopup?.item?.language : "",
      resolution:
        type === "updateErrorCode"
          ? openPopup?.item?.resolution?.filter((res) => res) // using filter to remove null from array
          : [],
      vendorErrorCode:
        type === "updateErrorCode" ? openPopup?.item?.vendorErrorCode : "",
      performanceImpact:
        type === "updateErrorCode" ? openPopup?.item?.performanceImpact : "",
      resolutionCategory:
        type === "updateErrorCode" ? openPopup?.item?.resolutionCategory : "",
      dangerPotentialDetected:
        type === "updateErrorCode"
          ? openPopup?.item?.dangerPotentialDetected
          : "",
      onsiteOrRemoteInterventionRequirement:
        type === "updateErrorCode"
          ? openPopup?.item?.onsiteOrRemoteInterventionRequirement
          : "",
      chargerStatusIndicator:
        type === "updateErrorCode"
          ? openPopup?.item?.chargerStatusIndicator
          : "",
      faultNumber:
        type === "updateErrorCode" ? openPopup?.item?.faultNumber : "",
      agentTroubleShooting:
        type === "updateErrorCode" ? openPopup?.item?.agentTroubleShooting : "",
      technicianTroubleShooting:
        type === "updateErrorCode"
          ? openPopup?.item?.technicianTroubleShooting
          : "",
      rmaProcess: type === "updateErrorCode" ? openPopup?.item?.rmaProcess : "",
      selfClearingAllowed:
        type === "updateErrorCode" ? openPopup?.item?.selfClearingAllowed : "",
      noOfSuccessfulChargeEventsBeforeClearing:
        type === "updateErrorCode"
          ? openPopup?.item?.noOfSuccessfulChargeEventsBeforeClearing
          : "",
    }),
    [openPopup, manufacturer]
  );

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!values.errorCode?.length && !values.vendorErrorCode?.length) {
        setErrors({
          ...errors,
          errorCode: "Please enter Error code or Vendor error code",
        });
        return;
      } else setErrors({});
      let fields = Object.keys(addEditInitialValues);
      const optionalFields = [
        "errorCode",
        "vendorErrorCode",
        "faultSource",
        "faultDefinition",
        "dangerPotentialDetected",
        "onsiteOrRemoteInterventionRequirement",
        "performanceImpact",
        "resolutionCategory",
        "chargerStatusIndicator",
        "faultNumber",
        "agentTroubleShooting",
        "technicianTroubleShooting",
        "rmaProcess",
        "selfClearingAllowed",
        "noOfSuccessfulChargeEventsBeforeClearing",
      ];
      const validData = validateNonEmptyFields(
        fields?.filter((item) => !optionalFields.includes(item)),
        values,
        setErrors
      );
      if (!validData) return;
      else {
        const index = values.resolution?.findIndex((item) => !item.length);
        if (index > 0) {
          setErrors({
            [`resolution${index}`]: messages.FIELD_REQUIRED,
          });
          return;
        } else setErrors({});
      }

      let resolution = {};
      values.resolution?.forEach(
        (item, i) => (resolution[`resolution${i + 1}`] = item)
      );
      const payload = {
        errorCodeGroupId: groupId,
        errorCode: values.errorCode?.trim(),
        faultSource: values.faultSource?.trim(),
        faultTitle: values.faultTitle?.trim(),
        faultDefinition: values.faultDefinition?.trim(),
        description: values.description?.trim(),
        manufacturer: values.manufacturer?.trim(),
        severity: values.severity,
        language: values.language,
        resolution: resolution,
        vendorErrorCode: values.vendorErrorCode,
        performanceImpact: values.performanceImpact,
        resolutionCategory: values.resolutionCategory,
        dangerPotentialDetected: values.dangerPotentialDetected,
        onsiteOrRemoteInterventionRequirement:
          values.onsiteOrRemoteInterventionRequirement,
        chargerStatusIndicator: values.chargerStatusIndicator,
        faultNumber: values.faultNumber,
        agentTroubleShooting: values.agentTroubleShooting,
        technicianTroubleShooting: values.technicianTroubleShooting,
        rmaProcess: values.rmaProcess,
        selfClearingAllowed: values.selfClearingAllowed,
        noOfSuccessfulChargeEventsBeforeClearing:
          values.noOfSuccessfulChargeEventsBeforeClearing,
      };
      setLoading(true);
      if (type === "addErrorCode") {
        const response = await apiProvider.post(ADD_ERROR_CODE, [payload]);
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Error code added successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshErrorCodes(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.response,
            type: "error",
          });
        }
      } else {
        const response = await apiProvider.post(
          `${ERROR_CODE}/${openPopup?.item?.oid}`,
          payload
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Error code updated successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshErrorCodes(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.response,
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [values, openPopup]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Loader isLoading={loading} />
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="errorCode"
            label="Error Code"
            value={values.errorCode}
            error={errors.errorCode}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="vendorErrorCode"
            label="Vendor Error Code"
            value={values.vendorErrorCode}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            disabled
            name="manufacturer"
            label="Manufacturer*"
            value={manufacturer}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="faultNumber"
            label="Fault Number"
            value={values.faultNumber}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="faultTitle"
            label="Fault Title*"
            value={values.faultTitle}
            error={errors.faultTitle}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="description"
            label="Description*"
            value={values.description}
            error={errors.description}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="faultSource"
            label="Fault Source"
            value={values.faultSource}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="faultDefinition"
            label="Fault Definition"
            value={values.faultDefinition}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="severity"
            label="Severity*"
            data-testid="severity"
            options={["High", "Medium", "Low", "Diagnostic", "Unknown"]}
            value={values.severity}
            error={errors.severity}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="language"
            label="Language*"
            data-testid="language"
            options={languages}
            value={values.language}
            error={errors.language}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Controls.Input
              name="resolution"
              label="Resolution*"
              data-testid="resolution"
              value={values.resolution?.[0]}
              error={errors.resolution}
              onChange={(e) => {
                let res = [...values.resolution];
                res[0] = e.target.value;
                setValues({ ...values, resolution: res });
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Controls.ActionButton
              disabled={
                values.resolution.length === 0 ||
                values.resolution.length === 3 ||
                values.resolution?.some((item) => item.trim()?.length === 0)
              }
              onClick={() => {
                setValues({
                  ...values,
                  resolution: [...values.resolution, ""],
                });
                setErrors({
                  ...errors,
                  [`resolution${values.resolution.length}`]: "",
                });
              }}
            >
              <AddCircleOutlineIcon />
            </Controls.ActionButton>
          </Grid>
        </Grid>
        {values.resolution?.length > 1 &&
          values.resolution?.slice(1)?.map((item, i) => (
            <Grid container alignItems="center" key={"resolution" + i}>
              <Grid item xs={11}>
                <Controls.Input
                  name="resolution"
                  label="Resolution*"
                  value={item}
                  error={errors[`resolution${i + 1}`]}
                  onChange={(e) => {
                    let res = [...values.resolution];
                    res[i + 1] = e.target.value;
                    setValues({
                      ...values,
                      resolution: res,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Controls.ActionButton
                  onClick={() =>
                    setValues({
                      ...values,
                      resolution: values.resolution?.filter(
                        (_, ind) => ind !== i + 1
                      ),
                    })
                  }
                >
                  <DeleteIcon />
                </Controls.ActionButton>
              </Grid>
            </Grid>
          ))}
        <Grid item xs={6}>
          <Controls.Select
            name="dangerPotentialDetected"
            label="Danger Potential Detected"
            options={["Yes", "No"]}
            value={values.dangerPotentialDetected}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="onsiteOrRemoteInterventionRequirement"
            label="Onsite Or Remote Intervention Requirement"
            options={["Onsite", "Remote", "Both"]}
            value={values.onsiteOrRemoteInterventionRequirement}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="resolutionCategory"
            label="Resolution Category"
            options={[
              "Non-recoverable",
              "Recoverable with trained user intervention",
              "Recoverable with user intervention",
              "Self-recoverable",
            ]}
            value={values.resolutionCategory}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="performanceImpact"
            label="Performance Impact"
            options={[
              "Unable to Charge",
              "Impaired Charging - Hardware",
              "Impaired Charging - Communications",
              "No Impact to Charging - Warning",
            ]}
            value={values.performanceImpact}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Select
            name="chargerStatusIndicator"
            label="Charger Status Indicator"
            options={chargerStatusIndicatorList}
            value={values.chargerStatusIndicator}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="agentTroubleShooting"
            label="Agent Troubleshooting"
            value={values.agentTroubleShooting}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="technicianTroubleShooting"
            label="Technician Troubleshooting"
            value={values.technicianTroubleShooting}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="rmaProcess"
            label="RMA Process"
            value={values.rmaProcess}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <div className="switchBox">
            <p>Self clearing allowed</p>
            <Switch
              name="selfClearingAllowed"
              checked={values.selfClearingAllowed}
              onChange={(e) => {
                setValues({ ...values, selfClearingAllowed: e.target.checked });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="noOfSuccessfulChargeEventsBeforeClearing"
            label="No of Successful Charge Events Before Self-Clearing"
            value={values.noOfSuccessfulChargeEventsBeforeClearing}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              text={
                type === "addErrorCode" ? "Add Error Code" : "Update Error Code"
              }
              data-testid={
                type === "addErrorCode"
                  ? "addErrorCodeSubmitBtn"
                  : "updateErrorCodeSubmitBtn"
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
};

const headCells = [
  { id: "vendorModel", label: "Model (OEM)" },
  { id: "soldAsManufacturer", label: "Sold as manufacturer" },
  { id: "soldAsModel", label: "Sold as model" },
];

export function ViewLinkedModels({ groupId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });

  const { MODELS_IN_GROUP } = indexedEndPoints;

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  useEffect(() => {
    const fetchLinkedModels = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        MODELS_IN_GROUP?.replace("id", groupId)
      );
      setLoading(false);
      if (response?.statusCode === 200) setData(response?.data?.body);
    };
    fetchLinkedModels();
  }, []);

  const ToolbarMemoised = useCallback(({ handleSearch }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Toolbar>
    );
  }, []);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn);

  return (
    <div>
      <ToolbarMemoised handleSearch={handleSearch} />
      <Loader isLoading={loading} />
      <TableContainer>
        <TableHead />
        {TableBody}
      </TableContainer>
      {TablePagination}
    </div>
  );
}

export default AddEditErrorCode;
