import React, { useEffect, useState } from "react";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageMainContent from "../../../components/PageMainContent";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getReportsHistory } from "../apiCall";
import ReportsHostoryTable from "./ReportsHistoryTable";
import sub from "date-fns/sub";
import add from "date-fns/add";
import Loader from "../../../components/Loader";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import NewFilterReports from "./NewReportsFilter";
import {
  commonApiActionCreator,
  usersActionCreator,
} from "../../../redux-state/actions";
import { getData } from "../../../utils/Storage";
import { variables } from "../../../constants/Variables";

const AllBreadcrumbsLinks = [
  {
    link: "/reports",
    title: "Reports",
  },
];

export default function ReportsHistory() {
  let preferredDepotsList = getData(variables.USER_PREFERRED_DEPOTS);
  preferredDepotsList = preferredDepotsList?.length
    ? preferredDepotsList?.split(",")
    : null;
  const dispatch = useDispatch();
  const { saveAllUsersGlobal } = bindActionCreators(
    usersActionCreator,
    dispatch
  );
  const { allUsers, isUsersLoaded } = useSelector(
    (state) => state.allUsersGlobal
  );
  const [reportsHistory, setReportsHistory] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [companiesRawData, setCompaniesRawData] = React.useState([]);
  const [depotsRawData, setDepotsRawData] = React.useState([]);
  const [searchedBy, setSearchedBy] = React.useState(null);
  const [generatedOnType, setGeneratedOnType] = React.useState("");
  const [customFromDate, setCustomFromDate] = React.useState("");
  const [customToDate, setCustomToDate] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isAccountsLoaded, setIsAccountsLoaded] = useState(false);
  const [tempQueryParamTempObj, setTempQueryParamTempObj] = useState({});
  const [userData, setUserData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchedString, setSearchedString] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  React.useEffect(() => {
    if (isUsersLoaded === false) {
      saveAllUsersGlobal();
    }
    getUserData();
  }, [isUsersLoaded]);

  const getUserData = () => {
    setUserData(allUsers);
  };
  const [allAccounts, setAllAccounts] = useState([]);
  const [reportType, setReportType] = useState([
    {
      name: "Report Type",
      isExpanded: true,
      checked: false,
      children: [
        {
          name: "Carbon credit report",
          checked: false,
          value: "LCFS",
        },
        {
          name: "Energy Dispensed - Sessions report",
          checked: false,
          value: "SESSIONS",
        },
        {
          name: "Energy Dispensed - Interval (15 minute) report",
          checked: false,
          value: "INTERVAL",
        },
        {
          name: "Charge Session Faults",
          checked: false,
          value: "SESSIONS_FAULTS",
        },
        {
          name: "Port Table",
          checked: false,
          value: "PORT_TABLE",
        },
        {
          name: "Station Table",
          checked: false,
          value: "STATION_TABLE",
        },
        {
          name: "Nayax Sessions",
          checked: false,
          value: "NAYAX_TRANSACTION",
        },
        {
          name: "Mobile Sessions",
          checked: false,
          value: "MOBILE_SESSIONS",
        },
        {
          name: "Charger Uptime report",
          checked: false,
          value: "CHARGER_UPTIME",
        },
        {
          name: "Vehicle Discovery Unregistered Vehicles",
          checked: false,
          value: "VEHICLE_DISCOVERY_UNREGISTERED_VEHICLES",
        },
        {
          name: "Charger Ports Rate plan report",
          checked: false,
          value: "PORT_RATE_PLAN",
        },
      ],
    },
  ]);

  useEffect(() => {
    getAccountsFilterRawData();
  }, []);

  useEffect(() => {
    isAccountsLoaded && getReportsForTable();
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    const tempData = [];
    tableData.forEach((single) => {
      single["accountName"] = single?.companyId?.map((comp) => {
        return companiesRawData.find((x) => x.companyId === comp)?.companyName;
      });
      single["depotName"] = single?.depotId?.map((comp) => {
        return depotsRawData.find((x) => x.depotId === comp)?.depotName;
      });

      tempData.push(single);
    });
    setReportsHistory(tempData);
  }, [tableData]);

  const getReportsForTable = async (payload = tempQueryParamTempObj) => {
    setLoading(true);
    let queryparam = `page=${page}&count=${rowsPerPage}`;
    if (searchedString !== "") queryparam += `&search=${searchedString}`;
    if (orderBy && order) {
      queryparam += `&orderBy=${orderBy}&order=${order == "asc" ? "1" : "-1"}`;
    }
    const res = await getReportsHistory(queryparam, payload);
    if (res?.statusCode === 200) {
      setTotalCount(res?.data?.count);
      setTableData(res?.data?.history);
    }
    setLoading(false);
  };

  const { saveAllTheDepots } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allTheDepotsResponse, isAllTheDepotsLoaded } = useSelector(
    (state) => state.getAllTheDepots_Global
  );
  const getAllTheDepotsResponse = () => {
    if (isAllTheDepotsLoaded === false) {
      return saveAllTheDepots();
    }
    return allTheDepotsResponse;
  };
  const getAccountsFilterRawData = async () => {
    setLoading(true);
    setIsAccountsLoaded(false);
    const queryparam = `page=${page}&count=${rowsPerPage}`;
    const payload = {};
    if (preferredDepotsList?.length) {
      payload["depotId"] = preferredDepotsList;
      setTempQueryParamTempObj(payload);
      setIsFiltered(true);
    }
    const res = await Promise.allSettled([
      getAllTheDepotsResponse(),
      getReportsHistory(queryparam, payload),
    ]);
    if (
      res[0]?.value?.statusCode === 200 &&
      res[1]?.value?.statusCode === 200
    ) {
      const reducer = (acc, item) => {
        const depotInfo = {
          ...item,
        };
        if (
          depotInfo.status === "terminated" ||
          depotInfo.status === "running" ||
          depotInfo.status === "delete"
        ) {
          if (acc[item.companyId]) {
            acc[item.companyId]["depotsList"].push(depotInfo);
          } else {
            acc[item.companyId] = {
              companyId: item.companyId,
              companyName: item.companyName,
              provisionId: item.provisionId,
              depotsList: [depotInfo],
            };
          }
        }
        return acc;
      };
      let result = res[0]?.value?.data?.reduce(reducer, {});
      let depotsResponseData = res[1]?.value?.data?.history;
      setTotalCount(res[1]?.value?.data?.count);
      result = Object.values(result);
      const onlyDepotsList = [];
      setCompaniesRawData(result);
      result.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });
      depotsResponseData.forEach((single) => {
        single["accountName"] = single?.companyId?.map((comp) => {
          return result.find((x) => x.companyId === comp)?.companyName;
        });
        single["depotName"] = single?.depotId?.map((comp) => {
          return onlyDepotsList.find((x) => x.depotId === comp)?.depotName;
        });
      });
      setReportsHistory(depotsResponseData);

      setDepotsRawData([...depotsRawData, ...onlyDepotsList]);
      result?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            singleChild["checked"] =
              preferredDepotsList?.length > 0 &&
              preferredDepotsList?.includes(singleChild.depotId);
          });
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: result,
        },
      ];
      setAllAccounts(tempAccounts);
    }
    setIsAccountsLoaded(true);
    setLoading(false);
  };

  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  const applyFilter = (
    tempAllAccounts,
    tempAllReportType,
    tempGeneratedOnType,
    tempSearchedBy
  ) => {
    toggleDrawer(false, "FILTER", {});
    let selectedAccFilters = [];
    let selectedReportTypeFilters = [];
    if (tempGeneratedOnType !== "custom" || (customFromDate && customToDate))
      setGeneratedOnType(tempGeneratedOnType);
    else setGeneratedOnType("");
    if (tempAllReportType[0].children.length) {
      selectedReportTypeFilters = tempAllReportType[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    setReportType([...tempAllReportType]);

    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    setAllAccounts([...tempAllAccounts]);
    setSearchedBy(tempSearchedBy);
    if (
      tempSearchedBy ||
      tempGeneratedOnType ||
      selectedReportTypeFilters.length !== 0 ||
      selectedAccFilters.length !== 0
    ) {
      let queryParamTempObj = {};
      if (tempGeneratedOnType) {
        let tempFromDate = [];
        let tempToDate = [];
        if (tempGeneratedOnType === "today") {
          const now = new Date();
          now.setUTCHours(0, 0, 0, 0);
          tempFromDate.push(now.toISOString());
          queryParamTempObj["fromDate"] = tempFromDate[0];
        } else if (tempGeneratedOnType === "Last7days") {
          tempFromDate.push(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 7,
            }).toISOString()
          );
          queryParamTempObj["fromDate"] = tempFromDate[0];
        } else if (tempGeneratedOnType === "Last30days") {
          tempFromDate.push(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 30,
            }).toISOString()
          );
          queryParamTempObj["fromDate"] = tempFromDate[0];
        } else if (tempGeneratedOnType === "Last90days") {
          tempFromDate.push(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 90,
            }).toISOString()
          );
          queryParamTempObj["fromDate"] = tempFromDate[0];
        } else if (tempGeneratedOnType === "Last6months") {
          tempFromDate.push(
            sub(new Date(Math.floor(new Date().getTime())), {
              months: 6,
            }).toISOString()
          );
          queryParamTempObj["fromDate"] = tempFromDate[0];
        } else if (tempGeneratedOnType === "Last12months") {
          tempFromDate.push(
            sub(new Date(Math.floor(new Date().getTime())), {
              months: 12,
            }).toISOString()
          );
          queryParamTempObj["fromDate"] = tempFromDate[0];
        } else if (tempGeneratedOnType === "custom") {
          if (customFromDate && customToDate) {
            tempFromDate.push(new Date(customFromDate).toISOString());
            tempToDate.push(
              add(new Date(Math.floor(new Date(customToDate).getTime())), {
                hours: 23,
                minutes: 59,
              }).toISOString()
            );
            queryParamTempObj["fromDate"] = tempFromDate[0];
            queryParamTempObj["toDate"] = tempToDate[0];
          }
        }
      }
      if (tempSearchedBy) {
        queryParamTempObj["searchedBy"] = tempSearchedBy;
      }
      if (selectedReportTypeFilters.length !== 0) {
        queryParamTempObj["reportType"] = selectedReportTypeFilters;
      }
      if (selectedAccFilters.length !== 0) {
        queryParamTempObj["depotId"] = selectedAccFilters;
      }
      getReportsForTable(queryParamTempObj);
      setTempQueryParamTempObj(queryParamTempObj);
      setIsFiltered(Object.values(queryParamTempObj)?.length ? true : false);
    } else {
      setIsFiltered(false);
      setTempQueryParamTempObj({});
      getReportsForTable({});
    }
  };

  return (
    <div>
      <React.Fragment>
        <OpsBreadcrumb
          AllBreadcrumbsLinks={AllBreadcrumbsLinks}
          title="History"
        />
        <PageMainContent>
          <ReportsHostoryTable
            reportsHistory={reportsHistory}
            toggleDrawer={toggleDrawer}
            isFiltered={isFiltered}
            isAccountsLoaded={isAccountsLoaded}
            getReportsForTable={getReportsForTable}
            tempQueryParamTempObj={tempQueryParamTempObj}
            totalCount={totalCount}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            setSearchedString={setSearchedString}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <NewCommonFilterDrawer
            DrawerOC={DrawerOC}
            toggleDrawer={toggleDrawer}
          >
            <NewFilterReports
              userData={userData}
              searchedBy={searchedBy}
              allAccounts={allAccounts}
              reportType={reportType}
              generatedOnType={generatedOnType}
              customFromDate={customFromDate}
              customToDate={customToDate}
              setCustomFromDate={setCustomFromDate}
              setCustomToDate={setCustomToDate}
              applyFilter={applyFilter}
            />
          </NewCommonFilterDrawer>
        </PageMainContent>
        <Loader isLoading={loading} />
      </React.Fragment>
    </div>
  );
}
