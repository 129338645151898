export const routePaths = {
  HOME: "/",
  LOGIN: "/login",
  USER_ALERT_SETTINGS: "/userAlertSettings",
  CREATE_ACCOUNT: "/create-account",
  CUSTOMERS: "/customers",
  INSTANCES: "/instances",
  SERVICE_STATUS: "/service-status",
  REPORTS: "/reports",
  CHARGERHEALTH: "/charger-health",
  OPENFAULTS: "/charger-health/open-Faults",
  OFFLINEPORTS: "/charger-health/offline-Ports",
  CHARGERFIRMWAREDETAILS: "/charger-details",
  CHARGER_HISTORY: "/charger-details/charger-history",
  CHARGER_REPLACEMENT_HISTORY: "/charger-details/replacement-history",
  USERS: "/users",
  CIRCUITMONITOR: "/circuit-monitor",
  CLLMALERTLOG: "/circuit-monitor/alert-log",
  REQUESTS: "/requests",
  COMPANIES: "/companies",
  REVIEWCONFIRM: "/requests/reviewconfirm",
  COMPANYDEPOTDETAILS: "/companies/:id/depots/:depotId?",
  BILLINGDEPOTDETAILS: "/billing/:id/billing-depots",
  BILLING: "/billing",
  CONFIGURATIONS: "/configurations",
  SERVICES: "/services",
  SERVICES_SCHEDULED: "/services/scheduled",
  SERVICES_HISTORY: "/services/history",
  PREPROVISIONING: "/pre-provisioning",
  BILLING_HISTORY: "/billing/:id/history",
  REPORTS_HISTORY: "/reports/history",
  DATA_CATALOG: "/dataCatalog",
  DATA_CATALOG_HISTORY: "/dataCatalog/history",
  ERROR_CODES_DASHBOARD: "/dataCatalog/errorCodesDashboard",
  ERROR_CODES: "/dataCatalog/errorCodesDashboard/errorCodes",
  BULK_UPLOAD_ERROR_CODES:
    "/dataCatalog/errorCodesDashboard/errorCodes/bulkUpload",
  CHARGERS_DASHBOARD: "/dataCatalog/chargersDashboard",
  VEHICLES_DASHBOARD: "/dataCatalog/vehiclesDashboard",
  CHARGERS: "/dataCatalog/chargersDashboard/chargers",
  VEHICLES: "/dataCatalog/vehiclesDashboard/vehicles",
  BULK_UPLOAD_VEHICLES: "/dataCatalog/vehiclesDashboard/vehicles/bulkUpload",
  BULK_UPLOAD_CHARGERS: "/dataCatalog/chargersDashboard/chargers/bulkUpload",
  VIN_VID_MAPPING: "/dataCatalog/vinVidMapping",
  VEHICLES_DISCOVERY: "/vehicles",
  VEHICLES_DISCOVERY_DEPOT_METRICS: "/vehicles/depot-details",
  BULK_UPLOAD_VIN_VID_MAPPINGS: "/dataCatalog/vinVidMapping/bulkUpload",
  UTILITY_RATES: "/dataCatalog/utilityRates",
  UTILITY_PROVIDER_PLANS: "/dataCatalog/utilityRates/provider",
  UTILITY_PLAN_SEARCH: "/dataCatalog/utilityRates/searchPlans",
  USAGE_ANALYTICS: "/usage-analytics",
  DATASYNC_EVENTS: "/datasync/events",
};
