import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Badge,
  Grid,
  InputAdornment,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import Popup from "../../../components/Popup";
import AddEditErrorCode, { ViewLinkedModels } from "./AddEditErrorCodes";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ToastMessage from "../../../components/ToastMessage";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import ErrorCodeDrawerDetails, {
  ErrorCodesFilter,
} from "./ErrorCodeDrawerDetails";
import PageMainContent from "../../../components/PageMainContent";
import { useHistory, useLocation } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import "../../../common.scss";
import { alllSeverityFilter } from "../../../constants/FilterConstants";
import GetAppIcon from "@mui/icons-material/GetApp";
import ExportCSV from "../../../components/ExportCSV";
import ComponentLoader from "../../../components/ComponentLoader";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
  {
    link: routePaths.ERROR_CODES_DASHBOARD,
    title: "Error Codes Dashboard",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const ErrorCodes = () => {
  const [groupName, setGroupName] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [refreshErrorCodes, setRefreshErrorCodes] = useState(true);
  const [errorCodesLanguages, setErrorCodesLanguages] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [severityFilter, setSeverityFilter] = useState(
    JSON.parse(JSON.stringify(alllSeverityFilter))
  );
  const [languageFilter, setLanguageFilter] = useState([]);

  const classes = useStyles();

  const componentPrivileges = useSelector(
    (state) => state?.user?.componentPrivilege?.dataCatalogErrorCode
  );
  const [headers, setHeaders] = React.useState([
    {
      key: "errorCode",
      name: "Error Code",
      checked: false,
    },
    {
      key: "vendorErrorCode",
      name: "Vendor ErrorCode",
      checked: false,
    },
    {
      key: "severity",
      name: "Severity",
      checked: false,
    },
    { key: "description", name: "Description", checked: false },
    { key: "performanceImpact", name: "Performance Impact", checked: false },
    {
      key: "dangerPotentialDetected",
      name: "Danger Potential Detected",
      checked: false,
    },
    { key: "faultDefinition", name: "Fault Definition", checked: false },
    { key: "faultSource", name: "Fault Source", checked: false },
    { key: "faultTitle", name: "Fault Title", checked: false },
    { key: "language", name: "Language", checked: false },
    {
      key: "onsiteOrRemoteInterventionRequirement",
      name: "Onsite Or Remote Intervention Requirement",
      checked: false,
    },
    { key: "resolutionCategory", name: "Resolution Category", checked: false },
    { key: "resolution", name: "Resolution", checked: false },
    {
      key: "chargerStatusIndicator",
      name: "Charger Status Inticator",
      checked: false,
    },
    { key: "faultNumber", name: "Fault Number", checked: false },
    {
      key: "agentTroubleShooting",
      name: "Agent Troubleshooting",
      checked: false,
    },
    {
      key: "technicianTroubleShooting",
      name: "Technician Troubleshooting",
      checked: false,
    },
    { key: "rmaProcess", name: "RMA Process", checked: false },
    {
      key: "selfClearingAllowed",
      name: "Self Clearing Allowed",
      checked: false,
    },
    {
      key: "noOfSuccessfulChargeEventsBeforeClearing",
      name: "No of Successful Charge Events Before Self-Clearing",
      checked: false,
    },
  ]);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const groupId = searchParams.get("groupId");
  const history = useHistory();

  const { ERROR_CODE, ERROR_CODE_LANGUAGES, ERROR_CODES_IN_GROUP } =
    indexedEndPoints;

  useEffect(() => {
    const fetchLanguages = async () => {
      const response = await apiProvider.getAll(ERROR_CODE_LANGUAGES);
      const data = response?.data?.body?.[0]?.languages;
      setErrorCodesLanguages(data);
      setLanguageFilter([
        {
          name: "Language",
          isExpanded: true,
          checked: false,
          children: data?.map((item) => ({
            name: item,
            checked: false,
            value: item,
          })),
        },
      ]);
    };
    fetchLanguages();
  }, []);

  useEffect(() => {
    const fetchErrorCodes = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        ERROR_CODES_IN_GROUP?.replace("id", groupId)
      );
      setLoading(false);
      if (response?.statusCode === 200) {
        setGroupName(response?.data?.body[0]?.groupName);
        setManufacturer(response?.data?.body[0]?.vendorManufacturer);
        setData(response?.data?.body[0]?.errorcodes);
        setFilteredData(response?.data?.body[0]?.errorcodes);
      }
      setRefreshErrorCodes(false);
    };
    refreshErrorCodes && fetchErrorCodes();
  }, [refreshErrorCodes]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const applyFilter = () => {
    toggleDrawer(false, "FILTER", {});
    let selectedSeverityFilter = [];
    let selectedLanguageFilter = [];

    if (severityFilter[0].children.length) {
      selectedSeverityFilter = severityFilter[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    if (languageFilter[0].children.length) {
      selectedLanguageFilter = languageFilter[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    let filterData = [];
    data?.forEach((row) => {
      (!selectedSeverityFilter?.length ||
        selectedSeverityFilter?.includes(row.severity)) &&
        (!selectedLanguageFilter?.length ||
          selectedLanguageFilter?.includes(row.language)) &&
        filterData.push(row);
    });
    setFilteredData(filterData);

    if (selectedSeverityFilter?.length || selectedLanguageFilter?.length)
      setIsFiltered(true);
    else setIsFiltered(false);
  };

  const clearAll = () => {
    severityFilter[0].checked = false;
    severityFilter[0].children?.map((single) => {
      single.checked = false;
      single.chargers?.map((ele) => {
        ele.checked = false;
      });
    });
    setSeverityFilter([...severityFilter]);
    languageFilter[0].checked = false;
    languageFilter[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setLanguageFilter([...languageFilter]);
  };

  const handleFilterDrawer = () => {
    setDrawerObj({
      isOpen: true,
      title: "FILTER",
      data: {},
      type: "filterData",
    });
  };

  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item, type: "" });
  };

  const handleViewMoreInfo = (row) => {
    setDrawerObj({
      isOpen: true,
      title: "Details",
      data: row,
      type: "viewData",
    });
  };

  const handleEditErrorCode = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Update Error Code",
      child: "updateErrorCode",
      item: row,
    });
  };

  const handleDeleteErrorCode = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure ?",
      subTitle: "You want to Delete this Error Code",
      type: "secondary",
      onConfirm: () => deleteErrorCode(row.oid),
    });
  };
  const deleteErrorCode = useCallback(async (oid) => {
    setLoading(true);
    const response = await apiProvider.del(`${ERROR_CODE}/${oid}`);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Error code deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshErrorCodes(true);
    } else {
      setToast({
        isOpen: true,
        message: response?.response,
        type: "error",
      });
    }
  }, []);

  const headCells = useMemo(() => {
    let hc = [
      { id: "errorCode", label: "Error Code" },
      { id: "vendorErrorCode", label: "Vendor Error Code" },
      { id: "language", label: "Language" },
      { id: "severity", label: "Severity" },
      { disableSorting: true, id: "action", label: "Action" },
    ];
    return hc;
  }, [componentPrivileges]);

  const allColumnsHeaders = [
    {
      key: "errorCode",
      label: "Error Code",
    },
    {
      key: "vendorErrorCode",
      label: "Vendor ErrorCode",
    },
    {
      key: "severity",
      label: "Severity",
    },
    { key: "description", label: "Description" },
    { key: "performanceImpact", label: "Performance Impact" },
    { key: "dangerPotentialDetected", label: "Danger Potential Detected" },
    { key: "faultDefinition", label: "Fault Definition" },
    { key: "faultSource", label: "Fault Source" },
    { key: "faultTitle", label: "Fault Title" },
    { key: "language", label: "Language" },
    {
      key: "onsiteOrRemoteInterventionRequirement",
      label: "Onsite Or Remote Intervention Requirement",
    },
    { key: "resolutionCategory", label: "Resolution Category" },
    { key: "resolution", label: "Resolution" },
    { key: "chargerStatusIndicator", label: "Charger Status Inticator" },
    { key: "faultNumber", label: "Fault Number" },
    { key: "agentTroubleShooting", label: "Agent Troubleshooting" },
    { key: "technicianTroubleShooting", label: "Technician Troubleshooting" },
    { key: "rmaProcess", label: "RMA Process" },
    { key: "selfClearingAllowed", label: "Self Clearing Allowed" },
    {
      key: "noOfSuccessfulChargeEventsBeforeClearing",
      label: "No of Successful Charge Events Before Self-Clearing",
    },
  ];

  const actionItems = useMemo(() => {
    let actions = [
      {
        type: "View More",
        label: "View More",
        testid: "viewMoreErrorCodeBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="View More Details">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleViewMoreInfo,
      },
    ];
    if (componentPrivileges?.includes("Update Error Code"))
      actions.push({
        type: "Edit",
        label: "Edit",
        testid: "editErrorCodeBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Edit">
            <EditOutlined fontSize="small" />
          </Tooltip>
        ),
        onClick: handleEditErrorCode,
      });
    if (componentPrivileges?.includes("Delete Error Code"))
      actions.push({
        type: "Delete",
        label: "Delete",
        testid: "deleteErrorCodeBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Delete">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleDeleteErrorCode,
      });
    return actions?.length > 0 ? actions : null;
  }, [componentPrivileges]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
    searchData: searchData,
  } = useTable(filteredData, headCells, filterFn, false, false, actionItems);

  const ToolbarMemoised = useCallback(
    ({ handleSearch, classes, setOpenPopup, loading, data }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Badge color="primary" variant="dot" invisible={!isFiltered}>
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={<TuneIcon className="filter_icon" />}
              onClick={handleFilterDrawer}
              className="filter"
              style={{
                border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
              }}
            />
          </Badge>
          <Grid sm item />
          <Grid>
            <ComponentLoader isLoading={loading}>
              <Controls.Button
                text="Download"
                variant="outlined"
                disabled={data.length === 0}
                startIcon={<GetAppIcon className="chdDownloadIcon" />}
                onClick={() =>
                  setOpenPopup({
                    isOpen: true,
                    title: "Export CSV",
                    child: "exportCSV",
                  })
                }
              />
            </ComponentLoader>
          </Grid>
          <PrivilegedComponent
            permission="Bulk Upload"
            module="dataCatalogErrorCode"
          >
            <Controls.Button
              text="Bulk Upload"
              variant="outlined"
              startIcon={<ArrowUpwardIcon />}
              onClick={() =>
                history.push(
                  `${routePaths.BULK_UPLOAD_ERROR_CODES}?groupId=${groupId}&manufacturer=${manufacturer}`
                )
              }
            />
          </PrivilegedComponent>
          <PrivilegedComponent
            permission="Add Error Code"
            module="dataCatalogErrorCode"
          >
            <Controls.Button
              text="Add Error Code"
              variant="outlined"
              data-testid="addErrorCodeBtn"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                setOpenPopup({
                  isOpen: true,
                  title: "Add Error Code",
                  child: "addErrorCode",
                  item: {},
                })
              }
            />
          </PrivilegedComponent>
        </Toolbar>
      );
    },
    [data, isFiltered]
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Error Codes"
      />
      <PageHeader title="Error Codes" />
      <PageMainContent>
        <div className="company_details" id="chargerManufacturerClipboard">
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={5}>
              <div className="single_details">
                <p className="title">Group name</p>
                <Controls.CopyToClipboard
                  name={groupName}
                  setToast={setToast}
                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className="single_details">
                <p className="title">Manufacturer</p>
                <Controls.CopyToClipboard
                  name={manufacturer}
                  setToast={setToast}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <Controls.Button
                text="Models"
                variant="outlined"
                startIcon={<VisibilityIcon className="viewDetailsIcon" />}
                onClick={() => {
                  setOpenPopup({
                    isOpen: true,
                    title: "Models Linked",
                    child: "viewLinkedModels",
                    item: {},
                  });
                }}
              />
            </Grid>
          </Grid>
        </div>

        <ToolbarMemoised
          handleSearch={handleSearch}
          classes={classes}
          setOpenPopup={setOpenPopup}
          loading={loading}
          data={searchData}
        />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth={"lg"}>
        {openPopup.child === "exportCSV" ? (
          <ExportCSV
            setOpenPopup={setOpenPopup}
            data={searchData}
            allColumnsHeaders={allColumnsHeaders}
            headers={headers}
            setHeaders={setHeaders}
            fileName={`Error Codes - ${groupName} - ${manufacturer}.csv`}
          />
        ) : openPopup.child === "viewLinkedModels" ? (
          <ViewLinkedModels groupId={groupId} />
        ) : openPopup.isOpen ? (
          <AddEditErrorCode
            groupId={groupId}
            manufacturer={manufacturer}
            type={openPopup.child}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setRefreshErrorCodes={setRefreshErrorCodes}
            setToast={setToast}
            languages={errorCodesLanguages}
          />
        ) : null}
      </Popup>

      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
      <NewCommonFilterDrawer DrawerOC={drawerObj} toggleDrawer={toggleDrawer}>
        {drawerObj?.type === "viewData" ? (
          <ErrorCodeDrawerDetails
            drawerObj={drawerObj?.data}
            showMakeModel={false} // Make and Model will be shown only in the troubleshooting guide in open faults page
          />
        ) : (
          <ErrorCodesFilter
            drawerObj={drawerObj?.data}
            severityFilter={severityFilter}
            setSeverityFilter={setSeverityFilter}
            languageFilter={languageFilter}
            setLanguageFilter={setLanguageFilter}
            applyFilter={applyFilter}
            clearAll={clearAll}
          />
        )}
      </NewCommonFilterDrawer>
    </>
  );
};

export default ErrorCodes;
