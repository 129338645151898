import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Controls from "../../components/controls/Controls";
import SingleTypeFilter from "../../components/all-filter-types/SingleFilter";
import FilterMultiSelect from "../../components/all-filter-types/MultiSelectWithAutoComplete";
import TabPanel from "../../components/all-filter-types/FilterTabPanel";
import NestedFilterWithSearch from "../../components/all-filter-types/NestedFilter";
import {
  checkNestedFilterSelected,
  checkSingleFilterSelected,
  checkFilterMultiSelectSelected,
} from "../../components/all-filter-types/isFilteredCheckFunctions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getTimeZones } from "../../services/TimeZoneService";
import { resetFilterFields } from "../../utils/Filters";

const NewDepotsFilter = (props) => {
  const {
    // clearAll,
    applyFilter,
    allDepotsType,
    allCdrFlag,
    allSimulatedFlag,
    allHomeChargingFlag,
    minaEnrolledFlag,
    isAdvenirEnrolledDepotFlag,
    allSharedChargingFlag,
    publishCDRsToCCDR,
    allEnableCircuitsFlag,
    countryCode,
    selectedTimeZone,
    cloudProvider,
    depotStatus,
    isRFIDCapable,
    isWorkplaceChargingDepotFlag,
    sendGetConfigurationFlag,
  } = props;
  const [value, setValue] = React.useState("Depot Type");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempSelectedTimeZone, setTempSelectedTimeZone] = React.useState(
    JSON.parse(JSON.stringify(selectedTimeZone))
  );

  const [tempAllDepotStatus, setTempAllDepotStatus] = React.useState(
    JSON.parse(JSON.stringify(depotStatus))
  );
  const [tempCloudProvider, setTempCloudProvider] = React.useState(
    JSON.parse(JSON.stringify(cloudProvider))
  );
  const [tempAllCountryCode, setTempAllCountryCode] = React.useState(
    JSON.parse(JSON.stringify(countryCode))
  );
  const [tempAllDepotType, setTempAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotsType))
  );

  const [tempAllCdrFlag, setTempAllCdrFlag] = React.useState(
    JSON.parse(JSON.stringify(allCdrFlag))
  );

  const [tempAllSimulatedFlag, setTempAllSimulatedFlag] = React.useState(
    JSON.parse(JSON.stringify(allSimulatedFlag))
  );

  const [tempAllHomeChargingFlag, setTempAllHomeChargingFlag] = React.useState(
    JSON.parse(JSON.stringify(allHomeChargingFlag))
  );

  const [tempMinaEnrolledFlag, setMinaEnrolledFlag] = React.useState(
    JSON.parse(JSON.stringify(minaEnrolledFlag))
  );

  const [tempIsAdvenirEnrolledDepotFlag, setIsAdvenirEnrolledDepotFlag] =
    React.useState(JSON.parse(JSON.stringify(isAdvenirEnrolledDepotFlag)));

  const [tempIsWorkplaceChargingDepotFlag, setIsWorkplaceChargingDepotFlag] =
    React.useState(JSON.parse(JSON.stringify(isWorkplaceChargingDepotFlag)));

  const [tempSendGetConfigurationFlag, setSendGetConfigurationFlag] =
    React.useState(JSON.parse(JSON.stringify(sendGetConfigurationFlag)));

  const [tempIsRFIDCapable, setTempIsRFIDCapable] = React.useState(
    JSON.parse(JSON.stringify(isRFIDCapable))
  );

  const [tempAllSharedChargingFlag, setTempAllSharedChargingFlag] =
    React.useState(JSON.parse(JSON.stringify(allSharedChargingFlag)));

  const [tempPublishCDRsToCCDR, setTempPublishCDRsToCCDR] = React.useState(
    JSON.parse(JSON.stringify(publishCDRsToCCDR))
  );

  const [tempAllEnableCircuitsFlag, setTempAllEnableCircuitsFlag] =
    React.useState(JSON.parse(JSON.stringify(allEnableCircuitsFlag)));

  const clearAll = () => {
    setTempSelectedTimeZone([]);
    const fields = [
      [tempAllSimulatedFlag, setTempAllSimulatedFlag],
      [tempAllHomeChargingFlag, setTempAllHomeChargingFlag],
      [tempMinaEnrolledFlag, setMinaEnrolledFlag],
      [tempIsAdvenirEnrolledDepotFlag, setIsAdvenirEnrolledDepotFlag],
      [tempIsRFIDCapable, setTempIsRFIDCapable],
      [tempAllCdrFlag, setTempAllCdrFlag],
      [tempAllSharedChargingFlag, setTempAllSharedChargingFlag],
      [tempPublishCDRsToCCDR, setTempPublishCDRsToCCDR],
      [tempAllEnableCircuitsFlag, setTempAllEnableCircuitsFlag],
      [tempAllDepotType, setTempAllDepotType],
      [tempAllCountryCode, setTempAllCountryCode],
      [tempCloudProvider, setTempCloudProvider],
      [tempAllDepotStatus, setTempAllDepotStatus],
      [tempIsWorkplaceChargingDepotFlag, setIsWorkplaceChargingDepotFlag],
      [tempSendGetConfigurationFlag, setSendGetConfigurationFlag],
    ];
    fields.forEach((field) => resetFilterFields(field));
  };

  const handleConfigFilterCheck = () => {
    if (
      checkSingleFilterSelected(tempAllCdrFlag) ||
      checkSingleFilterSelected(tempAllSimulatedFlag) ||
      checkSingleFilterSelected(tempAllHomeChargingFlag) ||
      checkSingleFilterSelected(tempMinaEnrolledFlag) ||
      checkSingleFilterSelected(tempIsAdvenirEnrolledDepotFlag) ||
      checkSingleFilterSelected(tempIsRFIDCapable) ||
      checkSingleFilterSelected(tempAllSharedChargingFlag) ||
      checkSingleFilterSelected(tempPublishCDRsToCCDR) ||
      checkSingleFilterSelected(tempAllEnableCircuitsFlag) ||
      checkSingleFilterSelected(tempIsWorkplaceChargingDepotFlag) ||
      checkSingleFilterSelected(tempSendGetConfigurationFlag)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Depot Type"
              value={"Depot Type"}
              icon={
                checkSingleFilterSelected(tempAllDepotType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="CONFIG FLAGS"
              value={"CONFIG FLAGS"}
              icon={
                handleConfigFilterCheck() ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Country"
              value={"Country"}
              icon={
                checkNestedFilterSelected(tempAllCountryCode) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Time Zone"
              value={"Time Zone"}
              icon={
                checkFilterMultiSelectSelected(tempSelectedTimeZone) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            {/* <Tab
              label="CLOUD PROVIDER"
              value={"CLOUD PROVIDER"}
              icon={
                checkSingleFilterSelected(tempCloudProvider) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            /> */}
            <Tab
              label="Depot Status"
              value={"Depot Status"}
              icon={
                checkSingleFilterSelected(tempAllDepotStatus) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Depot Type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotType}
                setFuntion={setTempAllDepotType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"CONFIG FLAGS"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllCdrFlag}
                setFuntion={setTempAllCdrFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllSimulatedFlag}
                setFuntion={setTempAllSimulatedFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllHomeChargingFlag}
                setFuntion={setTempAllHomeChargingFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempMinaEnrolledFlag}
                setFuntion={setMinaEnrolledFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempIsRFIDCapable}
                setFuntion={setTempIsRFIDCapable}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllSharedChargingFlag}
                setFuntion={setTempAllSharedChargingFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempPublishCDRsToCCDR}
                setFuntion={setTempPublishCDRsToCCDR}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllEnableCircuitsFlag}
                setFuntion={setTempAllEnableCircuitsFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempIsAdvenirEnrolledDepotFlag}
                setFuntion={setIsAdvenirEnrolledDepotFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempIsWorkplaceChargingDepotFlag}
                setFuntion={setIsWorkplaceChargingDepotFlag}
              />
            </div>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempSendGetConfigurationFlag}
                setFuntion={setSendGetConfigurationFlag}
              />
            </div>
          </TabPanel>

          <TabPanel value={value} index={"Country"}>
            <div className="filter_tab_vertical_body">
              <NestedFilterWithSearch
                _allData={tempAllCountryCode}
                setFuntion={setTempAllCountryCode}
                clearAll={clearAll}
              />
            </div>
          </TabPanel>

          <TabPanel value={value} index={"Time Zone"}>
            <div className="filter_tab_vertical_body">
              <FilterMultiSelect
                _allValues={[...new Set(getTimeZones())]}
                setFuntion={setTempSelectedTimeZone}
                name="Time Zone"
                selectedValue={tempSelectedTimeZone}
              />
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={"CLOUD PROVIDER"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempCloudProvider}
                setFuntion={setTempCloudProvider}
              />
            </div>
          </TabPanel> */}
          <TabPanel value={value} index={"Depot Status"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotStatus}
                setFuntion={setTempAllDepotStatus}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                checkSingleFilterSelected(tempAllDepotType) ||
                handleConfigFilterCheck() ||
                checkNestedFilterSelected(tempAllCountryCode) ||
                checkFilterMultiSelectSelected(tempSelectedTimeZone) ||
                checkSingleFilterSelected(tempCloudProvider) ||
                checkSingleFilterSelected(tempAllDepotStatus)
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(
                tempAllDepotType,
                tempAllCountryCode,
                tempCloudProvider,
                tempAllDepotStatus,
                tempAllCdrFlag,
                tempAllSimulatedFlag,
                tempAllHomeChargingFlag,
                tempMinaEnrolledFlag,
                tempIsAdvenirEnrolledDepotFlag,
                tempIsRFIDCapable,
                tempAllSharedChargingFlag,
                tempPublishCDRsToCCDR,
                tempAllEnableCircuitsFlag,
                tempSelectedTimeZone,
                tempIsWorkplaceChargingDepotFlag,
                tempSendGetConfigurationFlag
              )
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default NewDepotsFilter;
