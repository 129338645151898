export const errorCodesList = [
  "ConnectorLockFailure",
  "EVCommunicationError",
  "GroundFailure",
  "HighTemperature",
  "InternalError",
  "LocalListConflict",
  "NoError",
  "OtherError",
  "OverCurrentFailure",
  "OverVoltage",
  "PowerMeterFailure",
  "PowerSwitchFailure",
  "ReaderFailure",
  "ResetFailure",
  "UnderVoltage",
  "WeakSignal",
];
export const severityList = ["High", "Medium", "Low", "Diagnostic", "Unknown"];
export const performanceImpactList = [
  "Unable to Charge",
  "Impaired Charging - Hardware",
  "Impaired Charging - Communications",
  "No Impact to Charging - Warning",
];
export const dangerPotentialDetectedList = ["Yes", "No"];
export const resolutionCategoryList = [
  "Non-recoverable",
  "Recoverable with trained user intervention",
  "Recoverable with user intervention",
  "Self-recoverable",
];
export const onsiteOrRemoteInterventionRequirementList = [
  "Onsite",
  "Remote",
  "Both",
];
export const languagesList = [
  "it-IT",
  "fr-CA",
  "en-GB",
  "es-ES",
  "fr-FR",
  "en-US",
  "de-DE",
  "nl-NL",
];
export const chargerStatusIndicatorList = [
  "Not Illuminated",
  "Green Steady",
  "Green Flashing​",
  "Green Pulsing",
  "Blue Pulsing",
  "Red Steady​​",
  "Red Pulsing​",
  "Yellow Pulsing​​",
];
export const selfClearingAllowedList = ["true", "false"];

export const errorCodeFields = {
  errorCode: errorCodesList,
  vendorErrorCode: "Free form, up to 50 characters",
  description: "Free form",
  faultSource: "Free form",
  faultTitle: "Free form",
  faultDefinition: "Free form",
  language: languagesList,
  resolution1: "Free form",
  resolution2: "Free form",
  resolution3: "Free form",
  severity: severityList,
  performanceImpact: performanceImpactList,
  dangerPotentialDetected: dangerPotentialDetectedList,
  resolutionCategory: resolutionCategoryList,
  onsiteOrRemoteInterventionRequirement:
    onsiteOrRemoteInterventionRequirementList,
  chargerStatusIndicator: chargerStatusIndicatorList,
  faultNumber: "Free form",
  agentTroubleShooting: "Free form",
  technicianTroubleShooting: "Free form",
  rmaProcess: "Free form",
  selfClearingAllowed: selfClearingAllowedList,
  noOfSuccessfulChargeEventsBeforeClearing: "Free form",
};
