import React, { useEffect, useState, useMemo } from "react";
import { Grid, Badge, TextField, Divider } from "@mui/material";
import { format, subDays, getMonth, getDate, subMonths } from "date-fns";
import Controls from "../../components/controls/Controls";
import { DepotType, variables } from "../../constants/Variables";

import {
  getMonthlyAnalytics,
  getMapData,
  getActiveDepots,
  getChargingMode,
  getPortCountEachDay,
  getAnalyticsInsights,
  chargersTypeCountApiCall,
  getDashboardData,
  getBillablePorts,
  getBillableCustomersCount,
  getTotalCount,
} from "./AnalyticsService";
import "./Analytics.scss";

import DepotMap from "./Map/Index";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  AreaChart,
  Area,
  ReferenceDot,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { Tooltip as Muitooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../redux-state/actions";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { Link } from "react-router-dom";
import ChargerTypeGraph from "./analyticsGraphs/ChargerTypeGraph";
import AnalyticsFilter from "./AnalyticsFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { titleLabels } from "../../constants/TitleLabels";
import ComponentLoader from "../../components/ComponentLoader";
import {
  allCountryCodeForFilter,
  allDepotStatus,
  allDepotTypesForFilter,
  allHomeChargingFilter,
} from "../../constants/FilterConstants";
import Loader from "../../components/Loader";
import { getData } from "../../utils/Storage";
const colors = ["#2770D8", "#23A469", "#FAE100"];

const EnergyConsumptionAnalytics = () => {
  let preferredDepotsList = getData(variables.USER_PREFERRED_DEPOTS);
  preferredDepotsList = preferredDepotsList?.length
    ? preferredDepotsList?.split(",")
    : null;
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const { saveAllDepotsInfo } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allDepotsInfo, isDepotsInfoLoaded } = useSelector(
    (state) => state.getAllDepotsInfo_Global
  );
  const [totalChargingStats, setTotalChargingStats] = useState({
    totalEnergy: 0,
    totalSessions: 0,
  });
  const [totalManaged, setTotalManaged] = useState({
    totalEnergy: 0,
    totalSessions: 0,
  });
  const depotTypeList = [
    DepotType.TEST,
    DepotType.INTERNAL,
    DepotType.PILOT,
    DepotType.CUSTOMER,
    DepotType.DEALER,
  ];
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [depotsLoading, setDepotsLoading] = useState(false);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [billingCustomersLoading, setBillingCustomersLoading] = useState(false);
  const [billingPortsLoading, setBillingPortsLoading] = useState(false);
  const [portsLoading, setPortsLoading] = useState(false);
  const [chargingModeLoading, setChargingModeLoading] = useState(false);
  const [chargerTypeLoading, setChargerTypeLoading] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [periodicData, setPeriodicData] = useState([]);
  const [originalPeriodicData, setOriginalPeriodicData] = useState([]);
  const [portsChange, setPortsChange] = useState("");
  const [chargersTypeCount, setChargersTypeCount] = useState([]);
  const [chargingModePercentages, setChargingModePercentages] = useState({
    fullyAutomated: 0,
    supervised: 0,
    unmanaged: 0,
  });
  const [chargingModeCount, setChargingModeCount] = useState([]);
  const [depotCount, setDepotCount] = useState([]);
  const [depotChange, setDepotChange] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: subMonths(new Date(), 6),
    endDate: new Date() - 1,
  });
  const [periodic, setPeriodic] = useState("Monthly");
  const [portsCountDaily, setPortsCountDaily] = useState([]);
  const [analyticsInsightsData, setAnalyticsInsightsData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState([]);
  const [mapRawData, setMapRawData] = useState({});
  const [depotStatusList, setDepotStatusList] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [refreshActiveDepots, setRefreshActiveDepots] = useState(false);
  const [totalStatsLoading, setTotalStatsLoading] = useState(false);
  const [dateChangeLoading, setDateChangeLoading] = useState(false);
  const [activeCompaniesData, setActiveCompaniesData] = useState([]);
  const [activeCompaniesCount, setActiveCompaniesCount] = useState();
  const [activeDepotsList, setActiveDepotsList] = useState([]);
  const [activeDepotsCount, setActiveDepotsCount] = useState("");
  const [billableCustomersCount, setBillableCustomersCount] = useState("");
  const [billablePortsCount, setBillablePortsCount] = useState("");
  const [cardsData, setCardsData] = useState({
    REQUESTS: { title: "Requests", route: "/requests", count: 0 },
    BILLING: {
      title: "BILLING",
      route: "/billing",
      children: [
        {
          title: "Billable customers",
          tooltip:
            "A billable customer has one or more billable depots/chargers on either prepaid or monthly billing",
        },
        {
          title: "Billable charge ports",
          tooltip:
            "A billable charger has sent atleast one heartbeat/meter value and has been added to a billable (prepaid or monthly) site where atleast one of the chargers has dispensed 100 kWh+ of energy ",
        },
      ],
    },
  });
  const monthMap = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const [selectedDepotTypes, setDepotType] = useState(depotTypeList);
  const [countryCode, setCountryCode] = useState(
    JSON.parse(JSON.stringify(allCountryCodeForFilter))
  );
  const [selectedCountryCodes, setSelectedCountryCodes] = useState([]);
  const [depotStatus, setDepotStatus] = useState(
    JSON.parse(JSON.stringify(allDepotStatus))
  );
  const [selectedDepotStatus, setSelectedDepotStatus] = useState([]);
  const [allAccounts, setAllAccounts] = useState([
    {
      name: "Account and Depot",
      isExpanded: true,
      checked: false,
      children: [],
    },
  ]);
  const [selectedDepots, setSelectedDepots] = useState([]);

  const getDepotTypeFilter = () => {
    let depotTypeData = JSON.parse(JSON.stringify(allDepotTypesForFilter));
    depotTypeData[0]?.children.map((single) => {
      if (
        single.name == "CUSTOMER" ||
        single.name == "PILOT" ||
        single.name == "DEALER"
      )
        single.checked = true;
    });
    return depotTypeData;
  };
  const [allDepotTypes, setAllDepotTypes] = useState(getDepotTypeFilter());

  const [allHomeChargingFlag, setAllHomeChargingFlag] = useState(
    JSON.parse(JSON.stringify(allHomeChargingFilter))
  );

  useEffect(() => {
    const selectedDepotType = allDepotTypes[0].children
      .filter((row) => row.checked)
      .map((row) => row.name);
    const payload = {
      depotType: selectedDepotType,
    };
    if (preferredDepotsList?.length) payload["depotId"] = preferredDepotsList;
    setDepotType(selectedDepotType);
    setIsFiltered(Object.keys(payload).length > 0);
    getCardsData();
    getBillableCustomersData(payload);
    getBillablePortsData(payload);
    getTotalManagedData(payload);
    analyticsInsights(payload);
    getEachDayPortCount(payload);
    getChargersTypeCount(payload);
    getChargingModeCount(payload);
    getMonthlyAnalyticsData(payload);
    getDepotStatus();
    getMapDetails();
  }, []);

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getAllCompaniesData();
  }, [isCompaniesLoaded]);

  const getCardsData = async () => {
    setRequestsLoading(true);
    const res = await getDashboardData();
    setRequestsLoading(false);
    if (res?.statusCode === 200) {
      const data = {
        ...cardsData,
        REQUESTS: {
          ...cardsData.REQUESTS,
          count: res?.data?.find((row) => row.key === "REQUESTS")?.count,
        },
      };
      setCardsData(data);
    }
  };

  const getBillableCustomersData = async (payload) => {
    setBillingCustomersLoading(true);
    const res = await getBillableCustomersCount(payload);
    setBillingCustomersLoading(false);
    if (res?.statusCode === 200) setBillableCustomersCount(res?.data);
  };

  const getBillablePortsData = async (payload) => {
    setBillingPortsLoading(true);
    const res = await getBillablePorts(payload);
    setBillingPortsLoading(false);
    if (res?.statusCode === 200)
      setBillablePortsCount(
        res?.data
          ?.filter((row) => row?.depotType !== "TEST")
          ?.reduce((acc, curr) => acc + curr.billablePorts, 0)
      );
  };

  const getDepotInfo = () => {
    if (isDepotsInfoLoaded === false) {
      return saveAllDepotsInfo();
    }
    return allDepotsInfo;
  };

  const getDepotStatus = async () => {
    setDepotsLoading(true);
    await Promise.allSettled([getDepotInfo(), getActiveDepots()]).then(
      async (allPromise) => {
        setDepotsLoading(false);
        const depotInfoResponse = allPromise[0].value;
        const res = allPromise[1].value;
        if (res?.statusCode === 200) {
          const depotInfoResponseData = depotInfoResponse?.data;
          res.data = res?.data.map((ele) => {
            return {
              ...ele,
              ...depotInfoResponseData.find(
                (item) => item.depotId === ele.depotId && item
              ),
            };
          });
          let activeDepots = res?.data
            ?.filter((row) => row.activeStatus)
            ?.map((row) => row.depotId);
          setActiveDepotsList(activeDepots);
          setDepotStatusList(res?.data);
          setActiveDepotsCount(activeDepots?.length);
          setRefreshActiveDepots(true);
        }
      }
    );
    setRefreshActiveDepots(true);
  };

  const getAllCompaniesData = async () => {
    setCompaniesLoading(true);

    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      const accData = tempAllCompaniesV2.map((row) => {
        row.checked = false;
        row.isExpanded = true;
        if ("depotsList" in row) {
          row.depotsList?.map((item) => {
            item.checked =
              preferredDepotsList?.length > 0 &&
              preferredDepotsList?.includes(item.depotId);
          });
        }
        return row;
      });
      setAllAccounts([{ ...allAccounts[0], children: accData }]);
      setCompaniesLoading(false);
    }
  };

  const getMapDetails = async () => {
    setMapLoading(true);
    setLoading(true);
    const res = await getMapData();
    setMapLoading(false);
    setLoading(false);
    if (res?.statusCode === 200) {
      const data = res?.data;
      setMapRawData(data);
      const activeCompanies = data?.filter(
        (row) =>
          (row.depotType === DepotType.CUSTOMER ||
            row.depotType === DepotType.PILOT) &&
          (row.isActive || row.isBillingActivated)
      );
      const filteredActiveCompaniesCount = activeCompanies
        ?.filter(
          (row) =>
            row.depotType === DepotType.CUSTOMER ||
            row.depotType === DepotType.PILOT
        )
        ?.map((row) => row.companyId);
      setActiveCompaniesCount(
        [...new Set(filteredActiveCompaniesCount)].length
      );
      setActiveCompaniesData([
        ...new Set(activeCompanies?.map((row) => row.companyId)),
      ]);
    }
  };

  const analyticsInsights = async (payload) => {
    const res = await getAnalyticsInsights(payload);
    if (res?.statusCode === 200) {
      if (res?.data?.energy_change && res?.data?.session_change)
        setAnalyticsInsightsData(res?.data);
      else setAnalyticsInsightsData("");
    }
  };

  const getChargersTypeCount = async (payload) => {
    setChargerTypeLoading(true);
    const res = await chargersTypeCountApiCall(payload);
    setChargerTypeLoading(false);
    if (res?.statusCode === 200)
      setChargersTypeCount([
        { name: "AC Ports", value: res?.data?.acPorts },
        { name: "DC Ports", value: res?.data?.dcPorts },
      ]);
  };

  const getChargingModeCount = async (payload) => {
    setChargingModeLoading(true);
    const res = await getChargingMode(payload);
    setChargingModeLoading(false);
    if (res?.statusCode === 200) {
      setChargingModeCount([
        { name: "Fully Automated", value: res?.data?.fullyAutomated || 0 },
        { name: "Supervised", value: res?.data?.simple || 0 },
        { name: "Unmanaged", value: res?.data?.unmanaged || 0 },
      ]);
      const totalChargePorts =
        res?.data?.simple + res?.data?.fullyAutomated + res?.data?.unmanaged;
      setChargingModePercentages({
        "Fully Automated": Math.round(
          (res?.data?.fullyAutomated / totalChargePorts) * 100
        ),
        Supervised: Math.round((res?.data?.simple / totalChargePorts) * 100),
        Unmanaged: Math.round((res?.data?.unmanaged / totalChargePorts) * 100),
      });
    }
  };

  const getEachDayPortCount = async (payload) => {
    setPortsLoading(true);
    const res = await getPortCountEachDay(payload);
    setPortsLoading(false);
    if (res?.statusCode === 200) {
      const data = res?.data;
      data.reverse();
      setPortsChange(
        data?.length && data?.[0]?.ports !== 0
          ? (
              ((data[data.length - 1]?.ports - data[0]?.ports) /
                data[0]?.ports) *
              100
            )?.toFixed(2)
          : data[data.length - 1]?.ports * 100
      );

      let forEmptyResponse = [];
      data.forEach((element) => {
        forEmptyResponse.push({ date: element.date.slice(5), ports: 0 });
        element.date = element.date.slice(5);
      });
      if (data.length != 0) setPortsCountDaily(data);
      else setPortsCountDaily(emptyGraphData);
      if (data.length != 0) setEmptyGraphData(forEmptyResponse);
    }
  };

  useEffect(() => {
    let selectedDepots = [];
    allAccounts[0]?.children
      ?.filter((row) => row?.depotsList?.length)
      ?.forEach(({ depotsList }) =>
        selectedDepots.push(
          ...depotsList
            ?.filter((item) => item.checked)
            ?.map((item) => item.depotId)
        )
      );
    setSelectedDepots(selectedDepots);
  }, [allAccounts]);

  const makeDepotCount = () => {
    if (!refreshActiveDepots || !depotStatusList?.length) return;
    let depotCount = [];
    let last30DaysDepots = 0,
      oldDepotCount = 0;
    let oldDate = new Date();
    oldDate.setDate(oldDate.getDate() - 30);
    for (let i = 9; i >= -1; i--) {
      let date = new Date();
      let startMonth, startYear;
      date.setMonth(date.getMonth() - i);
      [startMonth, startYear] = [date.getMonth(), date.getFullYear()];
      date = new Date(startYear, startMonth, 1);
      let activeDepotCount = 0;
      const selectedHomeChargingFilters = allHomeChargingFlag[0]?.children
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.value);
      depotStatusList?.forEach((row) => {
        let depotCreatedAt = new Date(row.receivedAt);
        if (
          (selectedDepotTypes.length > 0
            ? selectedDepotTypes.includes(row.depotType)
            : true) &&
          (selectedCountryCodes.length > 0
            ? selectedCountryCodes.includes(row?.countryCode)
            : true) &&
          (selectedDepots.length > 0
            ? selectedDepots.includes(row?.depotId)
            : true) &&
          (selectedDepotStatus.length > 0
            ? (depotStatus[0].children[0].checked === true &&
                row.activeStatus &&
                !row.billingActivatedAt) ||
              (depotStatus[0].children[1].checked === true &&
                row.billingActivatedAt) ||
              (depotStatus[0].children[2].checked === true && !row.activeStatus)
            : true) &&
          row.activeStatus &&
          (selectedHomeChargingFilters?.length === 1
            ? selectedHomeChargingFilters[0] === row.isHomeChargingDepot
            : true)
        ) {
          if (i == -1) {
            // Calculate last30DaysDepots and oldDepotCount only for 1 loop iteration
            last30DaysDepots++;
            if (depotCreatedAt < oldDate) oldDepotCount++;
          }
          if (depotCreatedAt < date) activeDepotCount++;
        }
      });
      depotCount.push({
        name: format(date.setMonth(date.getMonth() - 1), "MMM-yyyy"),
        activeDepotCount: activeDepotCount,
      });
      if (i == -1) setActiveDepotsCount(activeDepotCount);
    }
    setDepotChange(
      oldDepotCount
        ? (((last30DaysDepots - oldDepotCount) / oldDepotCount) * 100)?.toFixed(
            2
          )
        : last30DaysDepots * 100
    );
    setDepotCount(depotCount);
    setRefreshActiveDepots(false);
  };

  useEffect(
    () => setTimeout(() => makeDepotCount(), [100]), // setTimeout is required to ensure the component is rendered with all filters applied
    [
      refreshActiveDepots,
      depotStatusList,
      selectedDepotTypes,
      selectedCountryCodes,
      selectedDepots,
      selectedDepotStatus,
      allHomeChargingFlag,
    ]
  );

  const getTotalManagedData = async (payload) => {
    setTotalStatsLoading(true);
    const res = await getTotalCount(payload);
    setTotalStatsLoading(false);
    if (res?.statusCode === 200) setTotalChargingStats({ ...res?.data });
  };

  const getMonthlyAnalyticsData = async (payload = {}) => {
    setDateChangeLoading(true);
    payload["interval"] = "Monthly";
    payload["start"] = format(new Date(dateRange.startDate), "yyyy-MM-dd");
    payload["end"] = format(new Date(dateRange.endDate), "yyyy-MM-dd");
    const res = await getMonthlyAnalytics(payload);
    if (res?.statusCode === 200) {
      const data = res?.data;
      setOriginalPeriodicData(JSON.parse(JSON.stringify([...data])));
      setPeriodicData(
        data.map((ele) => {
          ele.date = format(
            new Date(Date.parse(ele.date) - "19800000"),
            "MMM-yyyy"
          );
          ele.energyDeliveryed = Math.ceil(ele.energyDeliveryed) / 1000;
          ele.tooltip = ele.energyDeliveryed;
          return ele;
        })
      );
      let totalEnergy = 0,
        totalSessions = 0;
      data?.forEach((item) => {
        totalEnergy += item.energyDeliveryed;
        totalSessions += item.sessions;
      });
      setTotalManaged({ totalEnergy, totalSessions });
    }
    setDateChangeLoading(false);
  };

  const constructPayload = (
    tempAllDepotType,
    tempAllCountryCode,
    tempAllDepotStatus,
    tempAllAccounts,
    tempAllHomeChargingFlag
  ) => {
    let payload = {};
    let depotTypesArray = tempAllDepotType[0].children
      .filter((element) => element.checked)
      .map((element) => element.name);
    if (depotTypesArray.length) payload["depotType"] = depotTypesArray;
    setDepotType(depotTypesArray);
    const selectedCountryCode = tempAllCountryCode[0].children.reduce(
      (codes, countryCodes) => {
        const selectedChildElements = countryCodes.children.reduce(
          (CntryCode, curCh) => {
            if (curCh.checked) {
              CntryCode.push(curCh.value);
            }
            return CntryCode;
          },
          []
        );
        codes.push(...selectedChildElements);
        return codes;
      },
      []
    );
    if (selectedCountryCode.length > 0)
      payload["countryCode"] = selectedCountryCode;
    setSelectedCountryCodes(selectedCountryCode);
    const selectedDepotsStatus = tempAllDepotStatus[0]?.children
      ?.filter((item) => item?.checked)
      ?.map((item) => item?.value);
    setSelectedDepotStatus(selectedDepotsStatus);
    if (selectedDepotsStatus.length > 0) {
      payload["depotStatus"] = selectedDepotsStatus;
    }
    let accountsArray = [];
    if (tempAllAccounts?.length > 0) {
      tempAllAccounts?.[0]?.children?.forEach((row) => {
        let depots = row?.depotsList
          ?.filter((item) => {
            return item?.checked ? true : false;
          })
          ?.map((item) => item?.depotId);
        if (depots?.length) accountsArray.push(...depots);
      });
    }
    if (accountsArray?.length) payload["depotId"] = accountsArray;
    const selectedHomeChargingFilters = tempAllHomeChargingFlag[0]?.children
      ?.filter((item) => item?.checked)
      ?.map((item) => item?.value);
    const activeDepotArray = [];
    mapRawData?.forEach((row) => {
      (!depotTypesArray?.length || depotTypesArray?.includes(row.depotType)) &&
        (!selectedCountryCode?.length ||
          selectedCountryCode?.includes(row?.address?.countryCode)) &&
        (!selectedDepotsStatus?.length ||
          (tempAllDepotStatus[0].children[0].checked === true &&
            row.isActive) ||
          (tempAllDepotStatus[0].children[1].checked === true &&
            row.isBillingActivated) ||
          (tempAllDepotStatus[0].children[2].checked === true &&
            row.inactive)) &&
        (!accountsArray?.length || accountsArray?.includes(row.depotId)) &&
        !row.inactive &&
        (selectedHomeChargingFilters?.length !== 1 ||
          selectedHomeChargingFilters[0] === row.isHomeChargingDepot) &&
        activeDepotArray.push(row.companyId);
    });
    setActiveCompaniesCount([...new Set(activeDepotArray)].length);
    if (selectedHomeChargingFilters?.length === 1)
      payload["isHomeChargingDepot"] = selectedHomeChargingFilters[0] === true;
    return payload;
  };

  const applyFilter = (
    tempAllDepotType,
    tempAllCountryCode,
    tempAllDepotStatus,
    tempAllAccounts,
    tempAllHomeChargingFlag
  ) => {
    const payload = constructPayload(
      tempAllDepotType,
      tempAllCountryCode,
      tempAllDepotStatus,
      tempAllAccounts,
      tempAllHomeChargingFlag
    );
    setAllAccounts([...tempAllAccounts]);
    setDepotStatus([...tempAllDepotStatus]);
    setAllDepotTypes([...tempAllDepotType]);
    setCountryCode([...tempAllCountryCode]);
    setAllHomeChargingFlag([...tempAllHomeChargingFlag]);
    setIsFiltered(Object.keys(payload).length > 0);
    setRefreshActiveDepots(true);
    toggleDrawer(false, "", {});
    getTotalManagedData(payload);
    analyticsInsights(payload);
    getEachDayPortCount(payload);
    getChargersTypeCount(payload);
    getChargingModeCount(payload);
    getMonthlyAnalyticsData(payload);
    getBillableCustomersData(payload);
    getBillablePortsData(payload);
  };

  const totTitleCase = (value) => {
    return value.charAt(0) + value.slice(1).toLowerCase();
  };
  const getWeekRange = (dateString) => {
    let startDateString = `${
      monthMap[getMonth(subDays(new Date(dateString), 6))]
    }-${getDate(subDays(new Date(dateString), 6))}`;
    let endDateString = `${monthMap[getMonth(new Date(dateString))]}-${getDate(
      new Date(dateString)
    )}`;
    return `${startDateString} to ${endDateString}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const indexOfActiveRecord = periodicData.findIndex(
        (ele) => ele.date === label
      );
      const data = originalPeriodicData[indexOfActiveRecord].energyDeliveryed;
      const tooltipLabel = `${Math.ceil(data) / 1000} MWh`;
      return (
        <div className="chargingStatsTooltip">
          <p>{`${label}`}</p>
          <p className="tooltipLabel">{tooltipLabel}</p>
        </div>
      );
    }
    return null;
  };

  const fetchDataByCustomDate = () => {
    const payload = constructPayload(
      allDepotTypes,
      countryCode,
      depotStatus,
      allAccounts,
      allHomeChargingFlag
    );
    if (periodic === "Monthly") {
      getMonthlyAnalyticsData(payload);
    }
  };

  const mapData = useMemo(() => {
    return (
      <DepotMap
        rawData={mapRawData}
        selectedDepotTypes={selectedDepotTypes}
        selectedCountryCodes={selectedCountryCodes}
        selectedDepotStatus={selectedDepotStatus}
        selectedDepots={selectedDepots}
        depotStatusList={depotStatusList}
        depotStatus={depotStatus}
      />
    );
  }, [
    mapRawData,
    selectedDepotTypes,
    selectedCountryCodes,
    selectedDepotStatus,
    selectedDepots,
    depotStatusList,
  ]);

  const ChargingModeGraphTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="chargingModeTooltip">
          <label>{`${payload[0].name} : ${
            chargingModePercentages[payload[0].name]
          }%`}</label>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="analyticsPage">
        <p className="section_title">{titleLabels.HOME_TITLE}</p>
        <div className="home_Page_header">
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item xs={12} textAlign={"end"}>
              <ComponentLoader isLoading={companiesLoading}>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={isFiltered ? false : true}
                >
                  <Controls.Button
                    text="Filter"
                    variant="outlined"
                    startIcon={<FilterAltIcon className="homePageFilterIcon" />}
                    onClick={() => {
                      toggleDrawer(true, "FILTER", {});
                    }}
                    id="homePageFilter"
                  />
                </Badge>
                <p className="dataUpdation">Updates every 1 hour</p>
              </ComponentLoader>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="home_Page">
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="homePageCard">
                  <ComponentLoader isLoading={mapLoading}>
                    <div className="homePageCardContainer">
                      <div className="centerAligned">
                        <p className="homePageCardTitle">Active companies</p>
                        <Muitooltip title="An active company has one or more depots containing atleast one commissioned charger (from which Ford Pro systems have received a heartbeat or metervalue)">
                          <InfoIcon fontSize="small" className="infoIcon" />
                        </Muitooltip>
                      </div>
                      <div>
                        <Link to={"/companies"} className="homePageCardLink">
                          View
                        </Link>
                      </div>
                    </div>
                    <p className="count homePageCardCount">
                      {activeCompaniesCount}
                    </p>
                  </ComponentLoader>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="homePageCard">
                  <ComponentLoader isLoading={requestsLoading}>
                    <div className="homePageCardContainer">
                      <p className="homePageCardTitle">
                        {cardsData?.REQUESTS?.title}
                      </p>
                      <Link
                        to={cardsData?.REQUESTS?.route}
                        className="homePageCardLink"
                      >
                        View
                      </Link>
                    </div>
                    <p className="count homePageCardCount">
                      {cardsData?.REQUESTS?.count}
                    </p>
                  </ComponentLoader>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="homePageCard">
                  <ComponentLoader
                    isLoading={billingPortsLoading || billingCustomersLoading}
                  >
                    <div className="homePageCardContainer billingCard">
                      <p className="billingCardTitle">
                        {cardsData?.BILLING?.title}
                      </p>
                      <Link
                        to={cardsData?.BILLING?.route}
                        className="homePageCardLink"
                      >
                        View
                      </Link>
                    </div>
                    {cardsData?.BILLING?.children?.map((row) => (
                      <div key={row.title}>
                        <div className="billingChildren">
                          <p className="homePageCardTitle">{row.title}</p>
                          <Muitooltip title={row.tooltip}>
                            <InfoIcon fontSize="small" className="infoIcon" />
                          </Muitooltip>
                        </div>
                        <p className="count homePageCardCount">
                          {row.title === "Billable customers"
                            ? billableCustomersCount
                            : billablePortsCount}
                        </p>
                      </div>
                    ))}
                  </ComponentLoader>
                </div>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <div className="single_chart activeDepots">
              <ComponentLoader isLoading={depotsLoading}>
                <div className="card_inside_content">
                  <div className="centerAligned">
                    <p className="kpi_title homePageCardTitle">Active Depots</p>
                    <Muitooltip title="An active depot contains one or more commissioned chargers (from which FordPro systems have received heartbeat or meter value)">
                      <InfoIcon fontSize="small" className="infoIcon" />
                    </Muitooltip>
                  </div>
                  {depotChange !== "" && (
                    <div className="centerAligned">
                      <p className="count containerCount">
                        {activeDepotsCount}
                      </p>
                      {depotChange > 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleUpIcon
                            fontSize="small"
                            className="arrowCircleUpIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {depotChange ? (
                              <div className="positiveChange">
                                <span className="value">
                                  {depotChange + "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : depotChange < 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleDownIcon
                            fontSize="small"
                            className="arrowCircleDownIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {depotChange ? (
                              <div className="negativeChange">
                                <span className="value">
                                  {-1 * depotChange + "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : depotChange == 0 ? ( // don't use === here as 0.00 === 0 is false
                        <span className="zeroChange">
                          0% change in last 30 days
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <ResponsiveContainer height="80%">
                  <AreaChart
                    width={730}
                    height={250}
                    data={depotCount}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <defs>
                      <linearGradient
                        id="depotCount"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop offset="5%" stopColor="#23A469" stopOpacity={1} />
                        <stop
                          offset="95%"
                          stopColor="rgba(35, 164, 105, 0)"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <ReferenceDot y={0} stroke="#ffffff" />
                    <Area
                      fillOpacity={1}
                      isAnimationActive={false}
                      name="Active depots"
                      type="monotone"
                      dataKey="activeDepotCount"
                      stroke="#1B7E51"
                      fill="url(#depotCount)"
                      axisLine={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </ComponentLoader>
            </div>
          </Grid>
        </Grid>
      </div>
      <>
        <Grid container spacing={0} className="chargingStatsContainer">
          <Grid item xs={12}>
            <p className="chargingStatsTitle noTopMargin">
              Charging stats <span className="allTime">(all time)</span>
            </p>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ComponentLoader isLoading={totalStatsLoading}>
                <div className="homePageCard">
                  <div className="homePageCardContainer">
                    <p className="homePageCardTitle">Energy dispensed</p>
                  </div>
                  <p className="count homePageCardCount">
                    {`${(totalChargingStats?.totalEnergy / 1000).toFixed(
                      1
                    )} MWh`}
                  </p>
                </div>
              </ComponentLoader>
            </Grid>
            <Grid item xs={6}>
              <ComponentLoader isLoading={totalStatsLoading}>
                <div className="homePageCard">
                  <div className="billingChildren">
                    <p className="homePageCardTitle">Sessions managed</p>
                    <Muitooltip title="Sessions are only counted if a non zero amount of energy was successfully dispensed">
                      <InfoIcon fontSize="small" className="infoIcon" />
                    </Muitooltip>
                  </div>
                  <p className="count homePageCardCount">
                    {totalChargingStats?.totalSessions}
                  </p>
                </div>
              </ComponentLoader>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" className="chargingStatDivider" />
          <Grid item xs={6}>
            <p className="chargingStatsTitle">
              Charging stats{" "}
              <span className="allTime">(for selected range)</span>
            </p>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={1} className="dateContainer">
                <Grid item xs={12}>
                  <div className="fromToDates">
                    <DatePicker
                      className="mui-date-picked-reports"
                      id="date-picker-dialog"
                      label="From Date"
                      inputVariant="outlined"
                      disableFuture={true}
                      format="MM/dd/yyyy"
                      value={format(
                        new Date(dateRange.startDate),
                        "MM/dd/yyyy"
                      )}
                      onChange={(val) =>
                        setDateRange((dt) => ({ ...dt, startDate: val }))
                      }
                      maxDate={
                        dateRange.endDate
                          ? new Date(dateRange.endDate)
                          : undefined
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                    <DatePicker
                      className="mui-date-picked-reports"
                      id="date-picker-dialog"
                      label="To Date"
                      inputVariant="outlined"
                      disableFuture={true}
                      format="MM/dd/yyyy"
                      value={format(new Date(dateRange.endDate), "MM/dd/yyyy")}
                      onChange={(val) =>
                        setDateRange((dt) => ({ ...dt, endDate: val }))
                      }
                      minDate={
                        dateRange.startDate
                          ? new Date(dateRange.startDate)
                          : undefined
                      }
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                    <Controls.Button
                      text="Go"
                      onClick={() => fetchDataByCustomDate()}
                    />
                  </div>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className="single_chart innerContainer">
              <ComponentLoader isLoading={dateChangeLoading}>
                <div className="innerContainerItem">
                  <div className="card_inside_content">
                    <p className="kpi_title homePageCardTitle">
                      Energy dispensed
                    </p>
                    <div className="centerAligned">
                      <p className="count containerCount">
                        {<>{`${totalManaged.totalEnergy.toFixed(1)} MWh`}</>}
                      </p>
                      {analyticsInsightsData?.energy_change > 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleUpIcon
                            fontSize="small"
                            className="arrowCircleUpIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {analyticsInsightsData.energy_change ? (
                              <div className="positiveChange">
                                <span className="value">
                                  {analyticsInsightsData.energy_change +
                                    "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : analyticsInsightsData?.energy_change < 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleDownIcon
                            fontSize="small"
                            className="arrowCircleDownIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {analyticsInsightsData.energy_change ? (
                              <div className="negativeChange">
                                <span className="value">
                                  {-1 * analyticsInsightsData.energy_change +
                                    "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : analyticsInsightsData?.energy_change === 0 ? (
                        <span className="zeroChange">
                          0% change in last 30 days
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <ResponsiveContainer height="80%">
                    <BarChart data={periodicData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <ReferenceLine y={0} stroke="#ffffff" />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar
                        dataKey="energyDeliveryed"
                        fill="#2770D8"
                        barSize={20}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="innerContainerItem">
                  <div className="card_inside_content">
                    <div className="centerAligned">
                      <p className="kpi_title homePageCardTitle">
                        Sessions managed
                      </p>
                      <Muitooltip title="Sessions are only counted if a non zero amount of energy was successfully dispensed">
                        <InfoIcon fontSize="small" className="infoIcon" />
                      </Muitooltip>
                    </div>
                    <div className="centerAligned">
                      <p className="count containerCount">
                        {totalManaged.totalSessions}
                      </p>
                      {analyticsInsightsData?.session_change > 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleUpIcon
                            fontSize="small"
                            className="arrowCircleUpIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {analyticsInsightsData.session_change ? (
                              <div className="positiveChange">
                                <span className="value">
                                  {analyticsInsightsData.session_change +
                                    "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : analyticsInsightsData.session_change < 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleDownIcon
                            fontSize="small"
                            className="arrowCircleDownIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {analyticsInsightsData.session_change ? (
                              <div className="negativeChange">
                                <span className="value">
                                  {-1 * analyticsInsightsData.session_change +
                                    "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : analyticsInsightsData?.session_change === 0 ? (
                        <span className="zeroChange">
                          0% change in last 30 days
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <ResponsiveContainer height="80%">
                    <BarChart data={periodicData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <ReferenceLine y={0} stroke="#ffffff" />
                      <Bar dataKey="sessions" fill="#2770D8" barSize={20} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </ComponentLoader>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="single_chart portsContainer">
              <ComponentLoader isLoading={portsLoading}>
                <div className="card_inside_content">
                  <div className="centerAligned">
                    <p className="kpi_title homePageCardTitle">Active Ports</p>
                    <Muitooltip title="An active port has been added to a FPC depot and has logged atleast one heartbeat/meter value at that depot">
                      <InfoIcon fontSize="small" className="infoIcon" />
                    </Muitooltip>
                  </div>
                  {portsChange !== "" && portsCountDaily?.length > 0 && (
                    <div className="centerAligned">
                      <p className="count containerCount">
                        {portsCountDaily[portsCountDaily?.length - 1]?.ports}
                      </p>
                      {portsChange > 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleUpIcon
                            fontSize="small"
                            className="arrowCircleUpIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {portsChange ? (
                              <div className="positiveChange">
                                <span className="value">
                                  {portsChange + "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : portsChange < 0 ? (
                        <div className="centerAligned">
                          <ArrowCircleDownIcon
                            fontSize="small"
                            className="arrowCircleDownIcon"
                          />
                          <p className="insights_ptag valueChangeContainer">
                            {portsChange ? (
                              <div className="negativeChange">
                                <span className="value">
                                  {-1 * portsChange + "% in last 30 days"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : portsChange == 0 ? ( // don't use === here as 0.00 === 0 is false
                        <span className="zeroChange">
                          0% change in last 30 days
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <ResponsiveContainer height="80%">
                  <AreaChart
                    width={730}
                    height={250}
                    data={portsCountDaily}
                    margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <defs>
                      <linearGradient
                        id="portsCount"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop offset="5%" stopColor="#23A469" stopOpacity={1} />
                        <stop
                          offset="95%"
                          stopColor="rgba(35, 164, 105, 0)"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis
                      domain={[
                        (dataMin) => Math.trunc(dataMin / 100) * 100,
                        (dataMax) => Math.trunc(dataMax / 100) * 100 + 100,
                      ]}
                    />
                    <Tooltip />
                    <Area
                      fillOpacity={1}
                      isAnimationActive={false}
                      name="Ports"
                      type="monotone"
                      dataKey="ports"
                      stroke="#1B7E51"
                      fill="url(#portsCount)"
                      axisLine={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </ComponentLoader>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="chargerGraphsPadding">
            <div className="single_chart chargerContainers">
              <ComponentLoader isLoading={chargerTypeLoading}>
                <ChargerTypeGraph chargersTypeCount={chargersTypeCount} />
              </ComponentLoader>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="chargerGraphsPadding">
            <div className="single_chart chargerContainers">
              <ComponentLoader isLoading={chargingModeLoading}>
                <div className="chargingModeGraph">
                  <Muitooltip
                    title={"Charge Ports on charging mode across all depots"}
                  >
                    <p className="graphTooltip">Charging Mode Distribution</p>
                  </Muitooltip>
                  <p className="graphTooltip">(count by ports)</p>
                </div>
                <ResponsiveContainer>
                  <PieChart width={730} height={50}>
                    <Pie
                      cx="50%"
                      cy="50%"
                      data={chargingModeCount}
                      dataKey="value"
                      nameKey="name"
                      innerRadius={"55%"}
                      paddingAngle={2}
                      fill="#8884d8"
                      isAnimationActive={false}
                    >
                      {chargingModeCount.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colors[index % colors.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<ChargingModeGraphTooltip />} />
                    <Legend
                      align="right"
                      verticalAlign="middle"
                      layout="vertical"
                      iconSize="15"
                      iconType="circle"
                      wrapperStyle={{ marigin: "0 0 0 15px" }}
                      formatter={(value, entry) => (
                        <>
                          <span className="graphLegendKey">{`${value} - `}</span>
                          <span className="graphLegendValue">
                            {entry.payload.value}
                          </span>
                        </>
                      )}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </ComponentLoader>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="analyticsMapContainer">
              <ComponentLoader isLoading={mapLoading}>
                {mapRawData.length && mapData}
              </ComponentLoader>
            </div>
          </Grid>
        </Grid>
        <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          <AnalyticsFilter
            countryCode={countryCode}
            allDepotTypes={allDepotTypes}
            depotStatus={depotStatus}
            allAccounts={allAccounts}
            allHomeChargingFlag={allHomeChargingFlag}
            applyFilter={applyFilter}
          />
        </NewCommonFilterDrawer>
      </>
      <Loader isLoading={loading} />
    </>
  );
};

export default EnergyConsumptionAnalytics;
