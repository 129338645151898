// This code is deprecated.
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Table,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../components/controls/Controls";
import Loader from "../../components/Loader";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import PageMainContent from "../../components/PageMainContent";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import useTable from "../../components/UseTable";
import { useLocation } from "react-router-dom";
import { routePaths } from "../../constants/RoutePaths";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from "recharts";
import ToastMessage from "../../components/ToastMessage";
import TuneIcon from "@mui/icons-material/Tune";
import CommonDrawer from "../../components/SideDrawer";
import { InfoOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  searchInput: {
    width: "30%",
  },
}));

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <p style={{ margin: 0 }}>{`Error Code : ${label}`}</p>
        <p style={{ margin: 0 }}>{`Frequency : ${payload[0]?.value}`}</p>
        <p
          style={{ margin: 0 }}
        >{`Severity : ${payload[0]?.payload?.severity}`}</p>
      </div>
    );
  }

  return null;
};

const chartTableHeaders = [
  {
    id: "errorCode",
    label: "Error Code",
    styles: {
      maxWidth: "50px",
      padding: "10px",
      overflowWrap: "break-word",
    },
  },
  {
    id: "severity",
    label: "Severity",
    styles: { width: "50px", padding: "10px 0" },
  },
  {
    id: "faultFrequency",
    label: "Frequency",
    styles: { width: "50px", padding: "10px 0" },
  },
];

export default function DepotDetails() {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [chartFilterFn, setChartFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [chartTableData, setChartTableData] = useState([]);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const depotId = searchParams.get("depotId");
  const depotName = searchParams.get("depotName");
  const companyName = searchParams.get("companyName");
  const [chargerName, setChargerName] = useState("");
  const [chargePortName, setChargePortName] = useState("");
  const [chargePortData, setChargePortData] = useState({});
  const severity = ["High", "Medium", "Low", "Diagnostic", "Unknown"];
  const [filters, setFilters] = useState({ severity: severity });
  const [isFiltered, setIsFiltered] = useState(true);
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const classes = useStyles();
  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item, type: "" });
  };

  const allBreadcrumbsLinks = useMemo(
    () => [
      {
        link: routePaths.USAGE_ANALYTICS,
        title: "Usage Analytics",
      },
      {
        link: routePaths.DEPOTS,
        title: "Depots Faults",
      },
    ],
    []
  );

  const headCells = useMemo(
    () => [
      {
        id: "chargePortManufacturer",
        label: "Manufacturer",
        styles: { padding: "10px 5px", width: "80px" },
      },
      {
        id: "chargePortModel",
        label: "Model",
        styles: { padding: "10px 5px", width: "100px" },
      },
      {
        id: "chargerName",
        label: "Charger Name",
        styles: { padding: "10px 5px", width: "100px" },
        render: (row, col) => (
          <div>
            <div>{row[col]}</div>
            <div>{row.chargePortName}</div>
            <div>{row.FirmwareVersion}</div>
          </div>
        ),
      },
      {
        id: "totalFaultCount",
        label: "Faults",
        styles: { padding: "10px 5px", width: "30px" },
        render: (row, col) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row[col]}&nbsp;
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title={
                <div>
                  Faults Severity:
                  {filters?.severity?.includes("High") && (
                    <div>High : {row.highSeverityFaultCount}</div>
                  )}
                  {filters?.severity?.includes("Medium") && (
                    <div>Medium : {row.mediumSeverityFaultCount}</div>
                  )}
                  {filters?.severity?.includes("Low") && (
                    <div>Low : {row.lowSeverityFaultCount}</div>
                  )}
                  {filters?.severity?.includes("Diagnostic") && (
                    <div>Diagnostic : {row.diagnosticSeverityFaultCount}</div>
                  )}
                  {filters?.severity?.includes("Unknown") && (
                    <div>Unknown : {row.unknownSeverityFaultCount}</div>
                  )}
                </div>
              }
            >
              <InfoOutlined fontSize="14px" />
            </Tooltip>
          </div>
        ),
      },
    ],
    [chargePortName, filters]
  );

  const getTotalFaults = (row) => {
    let totalFaults = 0;
    if (filters?.severity?.includes("High"))
      totalFaults += row.highSeverityFaultCount;
    if (filters?.severity?.includes("Medium"))
      totalFaults += row.mediumSeverityFaultCount;
    if (filters?.severity?.includes("Low"))
      totalFaults += row.lowSeverityFaultCount;
    if (filters?.severity?.includes("Diagnostic"))
      totalFaults += row.diagnosticSeverityFaultCount;
    if (filters?.severity?.includes("Unknown"))
      totalFaults += row.unknownSeverityFaultCount;
    return totalFaults;
  };

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e?.target?.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const { GET_ALL_DEPOT_FAULTS } = indexedEndPoints;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${GET_ALL_DEPOT_FAULTS}/${depotId}`
      );
      setLoading(false);
      if (response?.statusCode >= 200 && response?.statusCode <= 299) {
        setData(response?.data);
        setFilteredData(response?.data);
        setChargerName(response?.data?.[0]?.chargerName);
        setChargePortName(response?.data?.[0]?.chargePortName);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!data?.length) return;
    setFilteredData(
      data?.map((row) => ({
        ...row,
        totalFaultCount: getTotalFaults(row),
      }))
    );
  }, [filters, data]);

  const onTableRowClick = (row) => {
    setChargerName(row.chargerName);
    setChargePortName(row.chargePortName);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${GET_ALL_DEPOT_FAULTS}/${depotId}/${chargePortName}?severity=${severity?.join(
          ","
        )}`
      );
      setLoading(false);
      if (response?.statusCode >= 200 && response?.statusCode <= 299) {
        setChargePortData({
          ...chargePortData,
          [chargePortName]: response?.data,
        });
        setChartTableData(
          response?.data
            ?.filter((row) => filters?.severity?.includes(row.severity))
            ?.slice(0, 5)
        );
      }
    };
    chargePortName && fetchData();
  }, [chargePortName]);

  useEffect(() => {
    chargePortName &&
      chargePortData[chargePortName]?.length > 0 &&
      setChartTableData(
        chargePortData[chargePortName]
          ?.filter((row) => filters?.severity?.includes(row.severity))
          ?.slice(0, 5)
      );
  }, [filters]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(
    filteredData,
    headCells,
    filterFn,
    false,
    false,
    null,
    onTableRowClick,
    0,
    false,
    null,
    0
  );

  const {
    tableContainer: ChartTableContainer,
    tableHead: ChartTableHead,
    tableBody: ChartTableBody,
  } = useTable(chartTableData, chartTableHeaders, chartFilterFn, false, true);

  const ToolbarMemoised = useCallback(
    ({ handleSearch, classes }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Grid item sm />
          <Badge
            color="primary"
            variant="dot"
            invisible={isFiltered ? false : true}
          >
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={
                <TuneIcon
                  style={{ color: isFiltered ? "#2770D8" : "#A3B6C7" }}
                />
              }
              onClick={() =>
                setDrawerObj({
                  isOpen: true,
                  title: "Filter",
                  data: severity,
                  type: "filterData",
                })
              }
              style={{
                border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
                padding: "4px 10px",
                color: "#3C5164",
              }}
            />
          </Badge>
        </Toolbar>
      );
    },
    [isFiltered]
  );

  useEffect(() => {
    setIsFiltered(filters?.severity?.length > 0);
  }, [filters]);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Depot Fault Analysis"
      />
      <PageHeader title="Depot Fault Analysis" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <Grid container>
          <Grid item xs={3}>
            <div className="single_details">
              <p className="title">Company Name</p>
              <p className="title_value">{companyName}</p>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="single_details">
              <p className="title">Depot Name</p>
              <p className="title_value">{depotName}</p>
            </div>
          </Grid>
        </Grid>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <div
          style={{
            display: "flex",
            padding: 0,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "49%",
              height: "100%",
              border: "1px solid #BFCDD9",
              boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.06)",
              borderRadius: "4px",
              padding: "5px",
            }}
          >
            {filteredData?.length === 0 ? (
              <p style={{ textAlign: "center" }}>No data available</p>
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead />
                    {TableBody}
                  </Table>
                </TableContainer>
                {TablePagination}
              </>
            )}
          </div>
          <div
            style={{
              width: "49%",
              border: "1px solid #BFCDD9",
              boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.06)",
              borderRadius: "4px",
              padding: "5px",
              marginBottom: "10px",
            }}
          >
            {filteredData?.length === 0 || chartTableData?.length === 0 ? (
              <p style={{ textAlign: "center" }}>No data available</p>
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Charger Name : {chargerName}</p>
                  <p>Port ID : {chargePortName}</p>
                </div>
                <BarChart
                  width={500}
                  height={250}
                  margin={{
                    top: 5,
                    bottom: 5,
                  }}
                  data={chargePortData[chargePortName]
                    ?.filter((row) => filters?.severity?.includes(row.severity))
                    ?.map((row) => ({
                      name: row.errorCode,
                      errorCode: row.errorCode,
                      frequency: row.faultFrequency,
                      severity: row.severity,
                    }))}
                >
                  <CartesianGrid strokeDasharray="0" vertical={false} />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    tick={false}
                    interval={0}
                    style={{ fontSize: "12px", color: "#3C5164" }}
                    label="Error Codes"
                  />
                  <YAxis
                    tickLine={false}
                    allowDecimals={false}
                    width={70}
                    label={{
                      angle: -90,
                      value: "Fault Frequency",
                      offset: 20,
                      position: "insideBottomLeft",
                    }}
                    style={{ fontSize: "12px", color: "#3C5164" }}
                  />
                  <ChartTooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey="frequency"
                    fill="#2770D8"
                    barSize={15}
                    barGap={1}
                  />
                </BarChart>
                <div>
                  <p style={{ textAlign: "center", margin: "8px 0" }}>
                    Top {chartTableData?.length} frequent occurring faults
                  </p>
                  <ChartTableContainer>
                    <Table>
                      <ChartTableHead />
                      {ChartTableBody}
                    </Table>
                  </ChartTableContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </PageMainContent>
      <ToastMessage toast={toast} setToast={setToast} />
      <CommonDrawer DrawerOC={drawerObj} toggleDrawer={toggleDrawer}>
        <div className="error_code_drawer">
          <p className="title">Severity</p>
          <FormGroup>
            {severity?.map((item) => (
              <FormControlLabel
                key={`severity-${item}`}
                control={
                  <Checkbox
                    checked={filters?.severity?.includes(item)}
                    onChange={() => {
                      let sv = filters?.severity?.includes(item)
                        ? filters?.severity?.filter((key) => key !== item)
                        : [...filters.severity, item];
                      setFilters({ ...filters, severity: sv });
                    }}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>
      </CommonDrawer>
    </>
  );
}
