// This code is deprecated.
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Grid, InputAdornment, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../components/controls/Controls";
import Loader from "../../components/Loader";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import PageMainContent from "../../components/PageMainContent";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import useTable from "../../components/UseTable";
import { useHistory, useLocation } from "react-router-dom";
import { routePaths } from "../../constants/RoutePaths";
import { getDepotFaults } from "./AnalyticsService";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import AnalyticsFilter from "./AnalyticsFilter";
import {
  allCountryCodeForFilter,
  allDepotStatus,
  allDepotTypesForFilter,
} from "../../constants/FilterConstants";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../redux-state/actions";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  searchInput: {
    width: "30%",
  },
}));
const allBreadcrumbsLinks = [
  {
    link: routePaths.USAGE_ANALYTICS,
    title: "Usage Analytics",
  },
];
export default function Depots() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const history = useHistory();
  const [DrawerOC, setDrawerOC] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const getDepotTypeFilter = () => {
    let depotTypeData = JSON.parse(JSON.stringify(allDepotTypesForFilter));
    depotTypeData[0]?.children.map((single) => {
      if (
        single.name == "CUSTOMER" ||
        single.name == "PILOT" ||
        single.name == "DEALER"
      )
        single.checked = true;
    });
    return depotTypeData;
  };
  const [allDepotTypes, setAllDepotTypes] = useState(getDepotTypeFilter());
  const [countryCode, setCountryCode] = useState(
    JSON.parse(JSON.stringify(allCountryCodeForFilter))
  );
  const [depotStatus, setDepotStatus] = useState(
    JSON.parse(JSON.stringify(allDepotStatus))
  );
  const [allAccounts, setAllAccounts] = useState([
    {
      name: "Account and Depot",
      isExpanded: true,
      checked: false,
      children: [],
    },
  ]);

  const classes = useStyles();

  const constructPayload = (
    tempAllDepotType,
    tempAllCountryCode,
    tempAllDepotStatus,
    tempAllAccounts
  ) => {
    let payload = {};
    let depotTypesArray = tempAllDepotType[0].children
      .filter((element) => element.checked)
      .map((element) => element.name);
    let depotStatusArray = tempAllDepotStatus[0].children
      .filter((element) => element.checked)
      .map((element) => element.value);
    if (depotTypesArray.length) payload["depotType"] = depotTypesArray;
    if (depotStatusArray.length) payload["depotStatus"] = depotStatusArray;
    const selectedCountryCode = tempAllCountryCode[0].children.reduce(
      (codes, countryCodes) => {
        const selectedChildElements = countryCodes.children.reduce(
          (CntryCode, curCh) => {
            if (curCh.checked) {
              CntryCode.push(curCh.value);
            }
            return CntryCode;
          },
          []
        );
        codes.push(...selectedChildElements);
        return codes;
      },
      []
    );
    if (selectedCountryCode.length > 0)
      payload["countryCode"] = selectedCountryCode;
    let accountsArray = [];
    if (tempAllAccounts?.length > 0) {
      tempAllAccounts?.[0]?.children?.forEach((row) => {
        let depots = row?.depotsList
          ?.filter((item) => {
            return item?.checked ? true : false;
          })
          ?.map((item) => item?.depotId);
        if (depots?.length) accountsArray.push(...depots);
      });
    }
    if (accountsArray?.length) payload["depotId"] = accountsArray;
    return payload;
  };

  const applyFilter = (
    tempAllDepotType,
    tempAllCountryCode,
    tempAllDepotStatus,
    tempAllAccounts
  ) => {
    const payload = constructPayload(
      tempAllDepotType,
      tempAllCountryCode,
      tempAllDepotStatus,
      tempAllAccounts
    );
    setCountryCode([...tempAllCountryCode]);
    setAllDepotTypes([...tempAllDepotType]);
    setDepotStatus([...tempAllDepotStatus]);
    setAllAccounts([...tempAllAccounts]);
    setIsFiltered(Object.keys(payload).length > 0);
    toggleDrawer(false, "", {});
    getTotalDepotFaults(payload);
  };

  const headCells = useMemo(
    () => [
      {
        id: "companyName",
        label: "Company Name",
      },
      {
        id: "depotName",
        label: "Depot Name",
      },
      {
        id: "chargePortCount",
        label: "Charge Ports",
      },
      {
        id: "faultFrequency",
        label: "Faults Count",
      },
    ],
    []
  );

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getAllCompaniesData();
  }, [isCompaniesLoaded]);

  const getAllCompaniesData = async () => {
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      const accData = tempAllCompaniesV2.map((row) => {
        row.checked = false;
        row.isExpanded = true;
        if ("depotsList" in row) {
          row.depotsList?.map((item) => {
            item.checked = false;
          });
        }
        return row;
      });
      setAllAccounts([{ ...allAccounts[0], children: accData }]);
    }
  };
  const getTotalDepotFaults = async (payload) => {
    setLoading(true);
    const res = await getDepotFaults(payload);
    setLoading(false);
    if (res?.statusCode === 200) {
      setData(res?.data);
    }
  };

  useEffect(() => {
    const payload = constructPayload(
      allDepotTypes,
      countryCode,
      depotStatus,
      allAccounts
    );

    setIsFiltered(Object.keys(payload).length > 0);
    getTotalDepotFaults(payload);
  }, []);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                .toString()
                .toLowerCase()
                .includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const onTableRowClick = (row) => {
    history.push(
      `${routePaths.DEPOT_DETAILS}?depotId=${row.depotId}&depotName=${row.depotName}&companyName=${row.companyName}`
    );
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, false, null, onTableRowClick);

  const ToolbarMemoised = useCallback(
    ({ handleSearch, classes }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Grid item sm />
          <Badge
            color="primary"
            variant="dot"
            invisible={isFiltered ? false : true}
          >
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={
                <TuneIcon
                  style={{ color: isFiltered ? "#2770D8" : "#A3B6C7" }}
                />
              }
              onClick={() => {
                toggleDrawer(true, "FILTER", {});
              }}
              style={{
                border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
                padding: "4px 10px",
                color: "#3C5164",
              }}
            />
          </Badge>
        </Toolbar>
      );
    },
    [isFiltered]
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Depots Faults"
      />
      <PageHeader title="Depots Faults" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
        <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          <AnalyticsFilter
            countryCode={countryCode}
            allDepotTypes={allDepotTypes}
            depotStatus={depotStatus}
            allAccounts={allAccounts}
            applyFilter={applyFilter}
          />
        </NewCommonFilterDrawer>
      </PageMainContent>
    </>
  );
}
