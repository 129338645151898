import { useState } from "react";
import "./ChargerHealth.scss";
import { useHistory } from "react-router-dom";
import { titleLabels } from "../../constants/TitleLabels";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

const FirmwareManagment = ({
  firmwareMgmtCount,
  depotId,
  model,
  depotType,
  countryCode,
  powerType,
}) => {
  const history = useHistory();
  const onClickChargePort = (firmwareStatusType) => {
    let tempObject = {
      depotID: depotId,
      soldAsIdList: model,
      powerType: powerType,
      depotType: depotType,
      countryCode: countryCode,
      firmwareStatus: firmwareStatusType,
    };

    let temp = Object.fromEntries(
      Object.entries(tempObject).filter(([key, value]) => value?.length)
    );

    if (temp) {
      const res = Object.keys(temp)
        .map(
          (key) =>
            `${key}=${
              Array.isArray(temp[key])
                ? tempObject[key].join(",")
                : tempObject[key]
            }`
        )
        .join("&");
      history.push(`/charger-details?${res}`);
    } else {
      history.push(`/charger-details`);
    }
  };

  return (
    <>
      <p className="section_title">{titleLabels.FIRMWAREMANAGMENT}</p>
      <div className="firmware_section">
        <div className="title_with_icon blockDisplay">
          <p className="module_titles">
            UPDATE AVAILABLE{" "}
            <Tooltip title={titleLabels.UPDATEAVAILABLE}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => onClickChargePort("updateAvailable")}
          >
            {"updateAvailable" in firmwareMgmtCount
              ? firmwareMgmtCount.updateAvailable
              : "-"}
          </p>
        </div>
        <div className="title_with_icon blockWithMargin">
          <p className="module_titles">
            UP TO DATE{" "}
            <Tooltip title={titleLabels.UPTODATE}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => onClickChargePort("upToDate")}
          >
            {"updated" in firmwareMgmtCount ? firmwareMgmtCount.updated : "-"}
          </p>
        </div>
        <div className="title_with_icon blockWithMargin">
          <p className="module_titles">
            UPDATE FAILED{" "}
            <Tooltip title={titleLabels.UPDATEFAILED}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => onClickChargePort("updateFailed")}
          >
            {"updateFailed" in firmwareMgmtCount
              ? firmwareMgmtCount.updateFailed
              : "-"}
          </p>
        </div>
        <div className="title_with_icon blockWithMargin">
          <p className="module_titles">
            N/A{" "}
            <Tooltip title={titleLabels.FIRMWARESTATUSNA}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => onClickChargePort("null")}
          >
            {"notApplicable" in firmwareMgmtCount
              ? firmwareMgmtCount.notApplicable
              : "-"}
          </p>
        </div>
      </div>
    </>
  );
};
export default FirmwareManagment;
